import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
export const GET_MAIN_INSTITUTE_REQUEST = "GET_MAIN_INSTITUTE_REQUEST";
export const GET_MAIN_INSTITUTE_FAILURE = "GET_MAIN_INSTITUTE_FAILURE";
export const GET_MAIN_INSTITUTE_SUCCESS = "GET_MAIN_INSTITUTE_SUCCESS";
const timeout = 8000;
export const getMainInsitute = (mainInstituteId) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_MAIN_INSTITUTE_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/main-institute/${mainInstituteId}`,
        config
      );
      console.log(res);
      if (res.status === 200) {
        dispatch({
          type: GET_MAIN_INSTITUTE_SUCCESS,
          payload: res.data.mainInstitute,
        });
      } else {
        dispatch({
          type: GET_MAIN_INSTITUTE_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: GET_MAIN_INSTITUTE_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};
