export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const setAlert = (alert) => (dispatch) => {
  dispatch({
    type: SET_ALERT,
    payload: alert,
  });
  setTimeout(() => {
    dispatch({
        type: REMOVE_ALERT,
      });  
  }, 7000);
};
