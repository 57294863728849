import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import {setAlert} from '../actions/Alert'
export const CREATE_STUDENT_REQUEST = "CREATE_STUDENT_REQUEST";
export const CREATE_STUDENT_FAILURE = "CREATE_STUDENT_FAILURE";
export const CREATE_STUDENT_SUCCESS = "CREATE_STUDENT_SUCCESS";

export const CREATE_MULTIPLE_STUDENTS_REQUEST =
  "CREATE_MULTIPLE_STUDENTS_REQUEST";
export const CREATE_MULTIPLE_STUDENTS_FAILURE =
  "CREATE_MULTIPLE_STUDENTS_FAILURE";
export const CREATE_MULTIPLE_STUDENTS_SUCCESS =
  "CREATE_MULTIPLE_STUDENTS_SUCCESS";

export const EDIT_STUDENT_REQUEST = "EDIT_STUDENT_REQUEST";
export const EDIT_STUDENT_FAILURE = "EDIT_STUDENT_FAILURE";
export const EDIT_STUDENT_SUCCESS = "EDIT_STUDENT_SUCCESS";

export const DELETE_STUDENT_REQUEST = "DELETE_STUDENT_REQUEST";
export const DELETE_STUDENT_FAILURE = "DELETE_STUDENT_FAILURE";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";


export const GET_GROUP_STUDENTS_REQUEST = "GET_GROUP_STUDENTS_REQUEST";
export const GET_GROUP_STUDENTS_FAILURE = "GET_GROUP_STUDENTS_FAILURE";
export const GET_GROUP_STUDENTS_SUCCESS = "GET_GROUP_STUDENTS_SUCCESS";
export const GET_STUDENT_DETAIL_REQUEST = "GET_STUDENT_DETAIL_REQUEST";
export const GET_STUDENT_DETAIL_FAILURE = "GET_STUDENT_DETAIL_FAILURE";
export const GET_STUDENT_DETAIL_SUCCESS = "GET_STUDENT_DETAIL_SUCCESS";
const timeout = 8000;
export const createStudent = (userData) => async (dispatch) => {
  const {
    name,
    email,
    phoneNumber,
    groupId,
    instituteId,
    password,
    parentDetails,
    isParentExists,
  } = userData;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  dispatch({
    type: CREATE_STUDENT_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        name,
        email,
        phoneNumber,
        groupId,
        instituteId,
        password,
        isParentExists,
        parentDetails: isParentExists && parentDetails,
      });
      console.log("body " + body);

      const res = await axios.post(baseUrl + "/api/student/add", body, config);
      console.log("res" + res);
      if (res.status === 200) {
        dispatch({
          type: CREATE_STUDENT_SUCCESS,
          payload: res.data.message,
        });
        dispatch(setAlert({message:res.data.message,alertType:'success'}))
      } else {
        dispatch({
          type: CREATE_STUDENT_FAILURE,
          payload: res.data.message.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch(setAlert({message:err.response.data.messages[0].msg,alertType:'error'}))
      dispatch({
        type: CREATE_STUDENT_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const createMultipleStudents = (userData) => async (dispatch) => {
  const { students, groupId, instituteId, password } = userData;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  dispatch({
    type: CREATE_MULTIPLE_STUDENTS_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        students,
        groupId,
        instituteId,
        password,
      });
      console.log("body " + body);

      const res = await axios.post(
        baseUrl + "/api/student/add-multiple",
        body,
        config
      );
      console.log("res" + JSON.stringify(res));
      if (res.status === 200) {
        dispatch({
          type: CREATE_MULTIPLE_STUDENTS_SUCCESS,
          payload: res.data.message,
        });
        dispatch(setAlert({message:res.data.message,alertType:'success'}))
      } else {
        dispatch({
          type: CREATE_MULTIPLE_STUDENTS_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch(setAlert({message:err.response.data.messages[0].msg,alertType:'error'}))
      dispatch({
        type: CREATE_MULTIPLE_STUDENTS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getStudentById = (userId) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_STUDENT_DETAIL_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/student/${userId}`, config);
      //      console.log(res);

      if (res.status === 200) {
        dispatch({
          type: GET_STUDENT_DETAIL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_STUDENT_DETAIL_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: GET_STUDENT_DETAIL_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getGroupStudents = (groupId) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_GROUP_STUDENTS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/student/group/${groupId}`,
        config
      );
      //console.log(res.data);

      if (res.status === 200) {
        let stds = [];
        res.data.students.forEach((tchr) => {
          let t = {
            ...tchr,
            id: tchr._id,
          };
          stds.push(t);
        });
        dispatch({
          type: GET_GROUP_STUDENTS_SUCCESS,
          payload: stds,
        });
      } else {
        dispatch({
          type: GET_GROUP_STUDENTS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: GET_GROUP_STUDENTS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};
export const editStudent = (id, userData) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const { name, email, phoneNumber } = userData;
  dispatch({
    type: EDIT_STUDENT_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        id,
        name,
        email,
        phoneNumber,
      });

      const res = await axios.put(`${baseUrl}/student/`, body, config);
      if (res.status === 200) {
        dispatch({
          type: EDIT_STUDENT_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: EDIT_STUDENT_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: EDIT_STUDENT_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const deleteStudent = ({studentId}) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  
  dispatch({
    type: DELETE_STUDENT_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
     

      const res = await axios.delete(`${baseUrl}/api/student/${studentId}`,  config);
      console.log(JSON.stringify(res));
      if (res.status === 200) {
        dispatch({
          type: DELETE_STUDENT_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: DELETE_STUDENT_FAILURE,
          payload: res.data.messages,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: DELETE_STUDENT_FAILURE,
        payload: err.response,
      });
    }
  }
};
