import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import Typography from "@material-ui/core/Typography";
import Table from "../components/TeacherTable";
import { makeStyles, Tooltip, Fab, Avatar } from "@material-ui/core";
import MaterialTable from "material-table";
import Theme from "../Theme";
import Select from "../components/Select";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Breadcrumbs from "../components/Breadcrumbs";
import { getAllInstitutesByMainInstituteId } from "../store/actions/Institutes";
import { getInstituteTeachers } from "../store/actions/Teachers";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import AppBar from '../components/AppBar'
const useStyles = makeStyles((theme) => ({
  table: {
    "& tbody>.MuiTableRow-root:hover": {
      background: "whitesmoke",
    },
  },
}));

const Teachers = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [institute, setInstitute] = React.useState("");
  const user = useSelector((state) => state.auth.user);
  const selectedInstitute = useSelector((state) => state.institutes.selectedInstitute);
  const insts = useSelector((state) => state.institutes.institutes);
  const tchrs = useSelector((state) => state.teachers.teachers);

  const handleInstituteChange = (e) => {
    e.preventDefault();
    setInstitute([e.target.value]);
    dispatch(getInstituteTeachers(insts[e.target.value]._id));
  };
  useEffect(() => {
    // dispatch(getAllInstitutesByMainInstituteId(user.id)); //not required, because no filtering is applied
  }, [dispatch, user.id]);

  const col = [
    {
      title: "Image",
      field: "imageUrl",
      render: (rowData) => (
        // <img
        //   src={rowData.imageUrl}
        //   style={{ width: 50, height: 50, borderRadius: "50%" }}
        // />
        <Avatar alt="image" src={rowData.imageUrl} />
      ),
      export: false,
    },

    { field: "name", title: "Name", width: 150 },
    { field: "email", title: "Email", width: 150 },
    { field: "phoneNumber", title: "Phone #", width: 150 },
    // { field: "role", title: "Role", width: 100 },
    {
      field: "createdAt",
      title: "Created on",
      width: 130,
      render: (rowData) => <div>{moment(rowData.createdAt).format('DD/MM/YYYY')}</div>,
      export: false
    },
  ];
  return (
    <div>
      <AppBar />

      <div style={{ padding: 16 }}>
        <Breadcrumbs institute={selectedInstitute.name} />
        <div
          style={{
            //width: 800,
            alignItems: "center",
            //justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <div style={{ width: 800 }}>
            <Select
              options={insts}
              minWidth={800}
              firstMenu="Select Institute"
              label="Institute"
              value={institute}
              onChange={handleInstituteChange}
            />
          </div> */}
          {/* <h5>Institute: {selectedInstitute.name}</h5> */}

          <div
            style={{
              width: 800,
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              //flex: 1,
              //display: "flex",
            }}
          >
            <div className={classes.table} style={{ maxWidth: "100%" }}>
              <MaterialTable
                theme={Theme}
                columns={col}
                data={tchrs}
                title="Teachers"
                options={{
                  exportButton: true,
                  // selection: true,
                }}
              />
            </div>
            {/* <Table rows={tchrs} /> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            bottom: 0,
            right: 0,
            marginBottom: 16,
            marginRight: 16,
            position: "fixed",
          }}
        >
          <Tooltip title="Create Teacher" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/Create Teacher");
              }}
            >
              <PersonAddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
          <Tooltip title="Create Multiple Teachers" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/Create Teachers");
              }}
            >
              <GroupAddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Teachers;
