import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert } from './Alert'

export const GET_ACCOUNT_DETAIL_REQUEST = "GET_ACCOUNT_DETAIL_REQUEST";
export const GET_ACCOUNT_DETAIL_FAILURE = "GET_ACCOUNT_DETAIL_FAILURE";
export const GET_ACCOUNT_DETAIL_SUCCESS = "GET_ACCOUNT_DETAIL_SUCCESS";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const VERIFY_ACCOUNT_REQUEST = "VERIFY_ACCOUNT_REQUEST";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const VERIFY_ACCOUNT_FAILURE = "VERIFY_ACCOUNT_FAILURE";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";

export const FORGOT_PASSWORD_REQUEST_REQUEST =
  "FORGOT_PASSWORD_REQUEST_REQUEST";
export const FORGOT_PASSWORD_REQUEST_SUCCESS =
  "FORGOT_PASSWORD_REQUEST_SUCCESS";
export const FORGOT_PASSWORD_REQUEST_FAILURE =
  "FORGOT_PASSWORD_REQUEST_FAILURE";
const timeout = 8000;
export const getAccountDetail = (instituteAdminId) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_ACCOUNT_DETAIL_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/api/institute-admin/${instituteAdminId}`, config);
      console.log(res);

      if (res.status === 200) {
        dispatch({
          type: GET_ACCOUNT_DETAIL_SUCCESS,
          payload: res.data.instituteAdmin,
        });
      } else {
        dispatch({
          type: GET_ACCOUNT_DETAIL_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: GET_ACCOUNT_DETAIL_FAILURE,
        payload: err.res,
      });
    }
  }
};

export const changePassword =
  ({ id, oldPassword, password, confirmPassword }) =>
    (dispatch) => {
      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(), timeout);
      dispatch({
        type: CHANGE_PASSWORD_REQUEST,
      });
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        id: id,
        oldPassword: oldPassword,
        password: password,
        confirmPassword: confirmPassword,
      });

      axios
        .post(`${baseUrl}/api/institute-admin/change-password`, body, config)
        .then((res) => {
          console.log("change p" + JSON.stringify(res));
          if (res.status === 200) {
            dispatch({
              type: CHANGE_PASSWORD_SUCCESS,
              payload: res.data.message,
            });
          } else {
            dispatch({
              type: CHANGE_PASSWORD_FAILURE,
              payload: res.data.message,
            });
          }
        })
        .catch((err) => {
          clearTimeout(id);
          dispatch({
            type: CHANGE_PASSWORD_FAILURE,
            payload: err.response.data.message,
          });
        });
    };

export const forgotPasswordRequest = ({ email, phone }) => (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  dispatch({
    type: FORGOT_PASSWORD_REQUEST_REQUEST,
  });
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    email,
    phoneNumber: phone
  });
  axios
    .post(baseUrl + "/api/institute-admin/forgot-password", body, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: FORGOT_PASSWORD_REQUEST_SUCCESS,
          payload: res.data.message,
        });
      } else {
        dispatch(setAlert({ message: res.data.messages[0].msg, alertType: 'error' }))

        dispatch({
          type: FORGOT_PASSWORD_REQUEST_FAILURE,
          payload: res.data.message,
        });
      }
    })
    .catch((err) => {
      clearTimeout(id);
      console.log(err);
      dispatch(setAlert({ message: err.response.data.messages[0].msg, alertType: 'error' }))

      dispatch({
        type: FORGOT_PASSWORD_REQUEST_FAILURE,
        payload: err.response.data.message,
      });
    });
};

// Set change password

export const verifyAccount =
  ({ email, code, password, confirmPassword }) =>
    (dispatch) => {
      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(), timeout);
      dispatch({
        type: VERIFY_ACCOUNT_REQUEST,
      });
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        email,
        password,
        confirmPassword,
        code,
      });
      axios
        .post(baseUrl + "/api/institute-admin/verify-account", body, config)
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            dispatch({
              type: VERIFY_ACCOUNT_SUCCESS,
              payload: res.data.message,
            });
          } else {
            dispatch({
              type: VERIFY_ACCOUNT_FAILURE,
              payload: res.data.message,
            });
          }
        })
        .catch((err) => {
          clearTimeout(id);

          dispatch({
            type: CHANGE_PASSWORD_FAILURE,
            payload: err.response.data.message,
          });
        });
    };

export const setAccountPassword =
  ({ email, code, password, confirmPassword }) =>
    (dispatch) => {
      console.log('set password action');
      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(), timeout);
      dispatch({
        type: SET_PASSWORD_REQUEST,
      });
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        email,
        password,
        confirmPassword,
        code,
      });
      console.log(body);
      axios
        .post(baseUrl + "/api/institute-admin/set/institute-admin-password", body, config)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            dispatch({
              type: SET_PASSWORD_SUCCESS,
              message: res.data.message,
            });
          } else {
            // console.log(res);
            dispatch(setAlert({ message: res.data.messages[0].msg, alertType: 'error' }))

            dispatch({
              type: SET_PASSWORD_FAILURE,
              payload: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setAlert({ message: err.response.data.messages[0].msg, alertType: 'error' }))
          clearTimeout(id);
          dispatch({
            type: SET_PASSWORD_FAILURE,
            payload: err.Error
          });
        });
    };
