import {
  GET_ACCOUNT_DETAIL_REQUEST,
  GET_ACCOUNT_DETAIL_FAILURE,
  GET_ACCOUNT_DETAIL_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  VERIFY_ACCOUNT_FAILURE,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
  FORGOT_PASSWORD_REQUEST_FAILURE,
  FORGOT_PASSWORD_REQUEST_REQUEST,
} from "../actions/Account";

const initialState = {
  account: null,
  isLoading: false,
  setpasswordSuccess: false,
  forgotPasswordStatus:false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ACCOUNT_DETAIL_SUCCESS:
      return {
        ...state,
        account: action.payload,
        isLoading: false,
      };
    case GET_ACCOUNT_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CHANGE_PASSWORD_REQUEST:

      return {
        ...state,
        isLoading: true,
        setpasswordSuccess: false
      };
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isLoading: false,
        setpasswordSuccess: true,
        forgotPasswordStatus:true
      };
    case SET_PASSWORD_FAILURE:

      return {
        ...state,
        error: action.payload,
        isLoading: false,
        setpasswordSuccess: false,
        
      };

    case FORGOT_PASSWORD_REQUEST_REQUEST:
      return {
        ...state,
        isLoading: true,
        forgotPasswordStatus:false
      };
    case FORGOT_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isLoading: false,
        forgotPasswordStatus:true

      };
    case CHANGE_PASSWORD_FAILURE:
    case FORGOT_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        forgotPasswordStatus:false
      };

    default:
      return state;
  }
}
