import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
const StudentDetailItem = (props) => {
  return (
    <div>
      <div
        style={{
          justifyContent: "flex-start",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <Typography style={{ minWidth: 200 }}>{props.title} </Typography>
        <Typography style={{ marginLeft: 16 }}>{props.answer}</Typography>
      </div>
    </div>
  );
};

export default StudentDetailItem;
