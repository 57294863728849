import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import Typography from "@material-ui/core/Typography";
import {
  getAllInstitutesByMainInstituteId,
  getInstituteDetail,
} from "../store/actions/Institutes";
import { getInstituteGroups } from "../store/actions/Groups";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import InstituteItem from "../components/InstituteItem";
import Header from "../components/Header";
import AppBar from '../components/AppBar'
import { getInstituteTeachers } from "../store/actions/Teachers";
import { setInstitute, setSelectedInstitute } from "../store/actions/Institutes";

const Institutes = (props) => {

  const dispatch = useDispatch();
  const insts = useSelector((state) => state.institutes.institutes);
  const user = useSelector((state) => state.auth.user);

  const onClickGetInstituteDetails = ({ instId, iName, institute }) => {
    dispatch(getInstituteDetail(instId));
    dispatch(setInstitute(instId));
    dispatch(setSelectedInstitute({ institute }))
    const data = { iName }
    props.history.push({
      pathname: `/Institute-Details`,
      data,
    });

  };


  const onClickGetInstituteContent = ({ instId, iName, institute }) => {
    dispatch(setInstitute(instId));
    dispatch(setSelectedInstitute({ institute }))
    const data = { iName }
    props.history.push({
      pathname: `/Institute-Content`,
      data,
    });
  };
  const onClickGetInstituteGroups = ({ iName, instId, institute }) => {
    dispatch(getInstituteGroups(instId));
    dispatch(setInstitute(instId));
    dispatch(setSelectedInstitute({ institute }))
    const data = { iName }
    props.history.push({
      pathname: `Groups`,
      data,
    });

  };
  const onClickGetInstituteTeachers = ({ iName, instId, institute }) => {
    dispatch(getInstituteTeachers(instId));
    dispatch(setInstitute(instId));
    dispatch(setSelectedInstitute({ institute }))

    const data = { iName }
    props.history.push({
      pathname: `/Teachers`,
      data,
    });
  }
  useEffect(() => {
    dispatch(getAllInstitutesByMainInstituteId(user.id));
  }, [dispatch, user.id]);

  return (
    <div>
      <AppBar />

      <div style={{padding:16}}>
      <Header header="Institutes" />
      <div
        style={{
          //width: 800,
          alignItems: "center",
          justifyContent: "center",
          //alignSelf: "center",
          //alignContent: "center",
          //flex: 1,
          //display: "flex",
          //flexDirection: "column",
        }}
      >
        <div style={{ width: 800, flexGrow: 1 }}>
          <Grid
            container
            direction="row"
            //justify="center"
            //alignItems="center"
            spacing={3}
          >
            {insts.map((inst, index) => (
              <Grid item sm={12} xs={12} md={6} lg={6} key={index.toString()}>
                <InstituteItem
                  institute={inst}
                  onClick={() => {
                    onClickGetInstituteGroups({ instId: inst._id, iName: inst.name });
                  }}
                  onClickDetails={() => onClickGetInstituteDetails({ instId: inst._id, iName: inst.name, institute: inst })}
                  onClickInstituteContent={() =>
                    onClickGetInstituteContent({ instId: inst._id, iName: inst.name, institute: inst })
                  }
                  onClickGetInstituteGroups={() =>
                    onClickGetInstituteGroups({ instId: inst._id, iName: inst.name, institute: inst })
                  }
                  onClickGetInstituteTeachers={() =>
                    onClickGetInstituteTeachers({ instId: inst._id, iName: inst.name, institute: inst })
                  }
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      </div>

    </div>
  );
};

export default Institutes;
