import React from 'react'
import {
    Button
} from "@material-ui/core";
const CustomButton = (props) => {

    return (
        <Button
            variant="contained"
            color="primary"
            style={{ width: 360, margin: '8px', height: '50px' }}
            onClick={props.onClick}
            size="medium"
        >
            {props.label}
        </Button>
    )
}

export default CustomButton