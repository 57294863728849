import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  ListItemText,
  ListItem,
  Divider,
  Typography,
  Paper,
  Avatar
} from "@material-ui/core";

import { withRouter } from "react-router-dom";

import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/School";
import PeopleIcon from "@material-ui/icons/People";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ForumIcon from "@material-ui/icons/Forum";
import ClassIcon from "@material-ui/icons/Class";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SportsKabaddiIcon from "@material-ui/icons/SportsKabaddi";
import { logout } from "../store/actions/Auth";
import { useDispatch } from "react-redux";
import logo from "../assets/images/impru-logo.png";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    color: "white",
    backgroundColor: "#1976d2",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
  },
}));

const DrawerItem = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const drawerList = [
    {
      text: "Institutes",
      icon: <HomeIcon style={{ color: "white" }} />,
      onClick: () => history.push("/"),
    },

    // {
    //   text: "Groups",
    //   icon: <ForumIcon style={{ color: "white" }} />,
    //   onClick: () => history.push("/groups"),
    // },
    // {
    //   text: "Teachers",
    //   icon: <PeopleIcon style={{ color: "white" }} />,
    //   onClick: () => history.push("/teachers"),
    // },
    // {
    //   text: "Students",
    //   icon: <PeopleIcon style={{ color: "white" }} />,
    //   onClick: () => history.push("/students"),
    // },
    {
      text: "My Account",
      icon: <SchoolIcon style={{ color: "white" }} />,
      onClick: () => history.push("/My Account"),
    },

    {
      text: "Logout",
      icon: <ExitToAppIcon style={{ color: "white" }} />,
      onClick: () => {
        dispatch(logout());
      },
    },
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            IMPRU - Institute Admin
          </Typography>
          <IconButton>
            <Avatar alt="Impru" src={logo} />
          </IconButton>
        </Toolbar> 
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {drawerList.map((item, index) => (
            <ListItem button key={item.text} onClick={item.onClick}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main> 
    </div>
  );
};

export default withRouter(DrawerItem);
