import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import {
  Tabs,
  List,
  Divider,
  CardMedia, Button,
  Fab, ListItem, ListItemText, Box, TextField, IconButton
} from "@material-ui/core";
import XLSX from "xlsx";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tab from "@material-ui/core/Tab";
import { Typography, Tooltip } from "@material-ui/core";
import TeacherListItem from "./TeacherListItem";
import StudentListItem from "./StudentListItem";
import GroupSubjectListItem from "./GroupSubjectListItem";
import image from "../assets/images/teamwork.png";
import { useDispatch, useSelector } from "react-redux";
import {

  getInstituteTeachers,
} from "../store/actions/Teachers";
import CustomTextField from '../components/CustomTextField'
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { withRouter } from 'react-router-dom';
import ImportExportIcon from '@material-ui/icons/ImportExport';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="span">
          <div style={{ marginLeft: 16, marginRight: 16 }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  chip: {
    margin: theme.spacing(0.5),
  },

  section2: {
    margin: theme.spacing(2),
  },
  subjects: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const SimpleTabs = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const instituteId = useSelector((state) => state.institutes.instituteId);
  const selectedGroup = useSelector((state) => state.groups.selectedGroup);
  const selectedInstitute = useSelector((state) => state.institutes.selectedInstitute);

  const [tabValue, setTabValue] = React.useState(0);
  const [student, setStudent] = React.useState("");
  const [filteredStudent, setFilteredStudent] = React.useState("");


  const [inputValue, setInputValue] = React.useState('');

  const filteredStudents = props.group.students.filter((el) => {
    //if no input the return the original
    if (props.searchField === '') {
      return el;
    }
    //return the item which contains the user input
    else {
      return el.name.toLowerCase().includes(props.searchField)
    }
  })

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    dispatch(getInstituteTeachers(instituteId))
  }, [instituteId, dispatch]);

  const exportStudentsList = () => {

    let newData = props.group.students.map((rec) => {
      let obj = {
        //  can create custom object
        Name: rec.name,
        Phone_Number: rec.phoneNumber,
        Email: rec.email,
        Parent_Name: rec.parentName,
        Parent_Email: rec.parentEmail,
        Parent_Phone_Number: rec.parentPhoneNumber
      };
      return obj;
    });
    const worksheet = XLSX.utils.json_to_sheet(newData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `${selectedInstitute.name} - ${selectedGroup.name}.xlsx`);
  };

  return (
    <div className={classes.root}>

      <AppBar position="static">

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="tabs"
          indicatorColor="secondary"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Teachers" {...a11yProps(1)} />
          <Tab label="Students" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        {props.group !== null && (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CardMedia

                image={
                  props.group.group.imageUrl
                    ? props.group.group.imageUrl
                    : image
                }
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 50,
                  borderWidth: 3,
                  borderColor: "grey",
                  marginTop: 8,
                  padding: 4,
                }}
              />

            </div>
            <div style={{
              textAlign: "center",
              padding: 8
            }}>
              <Typography variant="h6">
                {props.group.group.name}
              </Typography>
              <Typography style={{
                textAlign: "center",
              }}>Class: {props.group.group.standard.name}</Typography>
            </div>

            <div className={classes.subjects}>

              <List>
                <ListItem selected={true}>
                  <ListItemText primary="Description" />
                </ListItem>
              </List>
              <Typography
                color="textSecondary"
                variant="body2"
                style={{ marginBottom: 16 }}
              >
                {props.group.group.description}
              </Typography>
            </div>
            {/* <div className={classes.subjects}>
              <List>
                <ListItem selected={true}>
                  <ListItemText primary="Class" />
                </ListItem>
              </List>
              <GroupStandardListItem standard={props.group.group.standard} />
            </div> */}
            <div className={classes.subjects}>
              <List>
                <ListItem selected={true}>
                  <ListItemText primary="Subjects" />
                </ListItem>
              </List>
              <div>

                <List>
                  {props.group.subjectTeachers.map((sub) => (
                    <GroupSubjectListItem
                      key={sub.subject._id}
                      subject={sub.subject}
                    />
                  ))}
                </List>
              </div>
            </div>
          </div>
        )}
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        {props.group.subjectTeachers.map((item, i) => (
          <List key={i.toString()}>
            <TeacherListItem teacher={item} />
            <Divider variant="inset" component="li" />
          </List>
        ))}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <div style={{ display: 'flex', justifyContent: "flex-end" }}>
          <CustomTextField label="Search" onChange={props.onChange} value={props.searchField} />

          <IconButton edge="end" aria-label="unassign" onClick={exportStudentsList}>
            <ImportExportIcon color="primary" />
          </IconButton>
        </div>
        {/* <Autocomplete style={{ marginTop: 16 }} options={props.group.students} getOptionLabel={(option) => option.name}
          inputValue={student.name} onChange={(event, newValue) => { setStudent(newValue.name) }} onInputChange={(event, newInputValue) => { setFilteredStudent(newInputValue.name) }} label="Search" renderInput={(params) =>
            <TextField {...params} label="Search" variant="outlined" />} /> */}
        {filteredStudents.map((student, i) => (
          <List dense key={i.toString()}>
            <StudentListItem student={student} />
            <Divider variant="inset" component="li" />
          </List>
        ))}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            bottom: 0,
            right: 0,
            marginBottom: 16,
            marginRight: 16,
            position: "fixed",
          }}
        >
          <Tooltip title="Create New Student" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/Create Student");
              }}
            >
              <PersonAddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
          <Tooltip title="Create Multiple Student" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/Create Students");
              }}
            >
              <GroupAddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
        </div>
      </TabPanel>
    </div>
  );
};
export default withRouter(SimpleTabs);
