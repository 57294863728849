import React, { useEffect } from "react";
import Drawer from "../components/Drawer";

import { Fab, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GroupTab from "../components/GroupTab";
import Breadcrumbs from "../components/Breadcrumbs";
import { useSelector } from "react-redux";
import AppBar from '../components/AppBar'

const GroupDetail = (props) => {

  const instituteId = useSelector((state) => state.institutes.instituteId);
  const groupId = useSelector((state) => state.groups.groupId);
  const group = useSelector((state) => state.groups.group);
  const isLoading = useSelector((state) => state.groups.isLoading);
  const selectedInstitute = useSelector((state) => state.institutes.selectedInstitute);
  const selectedGroup = useSelector((state) => state.groups.selectedGroup);

  const [searchField, setSearchField] = React.useState("")

  const onChangeSearch = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearchField(lowerCase);
  }
  console.log(searchField);
  useEffect(() => {
    if (group == null && isLoading == false) {
      props.history.push("/Home");
    }
  }, []);

  return (
    <div>
      <AppBar />

      <div style={{ padding: 16 }}>
        <Breadcrumbs institute={selectedInstitute.name} group={selectedGroup.name} />
        <div
          style={{
            //width: 800,
            alignItems: "center",
            justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            //  display: "flex",
            //flexDirection: "column",
          }}
        >
          <div>
            <div style={{ width: 800 }}>
              {/* <div style={{ flexDirection: 'row', display: "flex" }}>
                <h5>Institute: {selectedInstitute.name} -</h5>
                <h5>Group: {selectedGroup.name}</h5>
              </div> */}
              {group !== null ? <GroupTab searchField={searchField} onChange={onChangeSearch} group={group} students={group.students.map(st=>st.name)} /> : <div />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDetail;
