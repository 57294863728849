import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "360px",
    },
  },
}));

const CustomTextField = (props) => {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        id="outlined-secondary"
        label={props.label}
        variant="outlined"
        color="primary"
        value={props.value}
        onChange={props.onChange}
        style={props.width ? { width: props.width } : { width: 360 }}
      required
      size="medium"
        // helperText={props.error && props.error}
        // error={props.error ? true : false}
      />

      {/* {props.error && (
        <Typography
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
          // className={classes.formControl}
          variant="body2"
          color="secondary"
        >
          {props.error ? props.error : "    "}
        </Typography>
      )} */}
    </form>
  );
};
export default CustomTextField;
