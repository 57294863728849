import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import Select from "../components/Select";
import CustomTextField from "../components/CustomTextField";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, Button, Typography } from "@material-ui/core";
import Breadcrumbs from "../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { createTeacher } from "../store/actions/Teachers";
import { getAllInstitutesByMainInstituteId } from "../store/actions/Institutes";
import AppBar from '../components/AppBar'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const CreateTeacher = (props) => {
  const classes = useStyles();
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const insts = useSelector((state) => state.institutes.institutes);
  const selectedInstitute = useSelector((state) => state.institutes.selectedInstitute);

  const message = useSelector((state) => state.teachers.message);
  const error = useSelector((state) => state.teachers.error);
  const alert = useSelector((state) => state.alert.alert);
  const [institute, setInstitute] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [commonError, setCommonError] = React.useState("");
  const [instituteError, setInstituteError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");

  useEffect(() => {
    dispatch(getAllInstitutesByMainInstituteId(user.id));
  }, [dispatch, user.id]);

  const handleInstituteChange = (e) => {
    e.preventDefault();
    //console.log("change");
    //console.log(e.target.value);
    setInstitute([e.target.value]);
    setInstituteError("");
    setCommonError("")
  };

  const handleNameChange = (e) => {
    e.preventDefault();
    //console.log("change");
    //console.log(e.target.value);
    setName(e.target.value);
    setNameError("");
    setCommonError("")

  };
  const handleEmailChange = (e) => {
    e.preventDefault();
    //console.log("change");
    //console.log(e.target.value);
    setEmail(e.target.value);
    setEmailError("");
    setCommonError("")

  };

  const handlePhoneNumberChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setPhone(e.target.value);
    setPhoneError("");
    setCommonError("")

  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setPassword(e.target.value);
    setPasswordError("");
    setCommonError("")

  };
  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
    setCommonError("")

  };
  const validate = () => {
    // if (institute === "") {
    //   setInstituteError("Institute  is Required");
    //   setCommonError("Institute  is Required");

    //   return false;
    // }
    if (name === "") {
      setNameError("Name is required");
      setCommonError("Name is required");

      return false;
    }

    if (email === "") {
      setEmailError("Email is required");
      setCommonError("Email is required");

      return false;
    }
    if (email.length > 0) {
      if (!regexp.test(email)) {
        setEmailError("Invalid Email");
        setCommonError("Invalid Email");

        return false;
      }
    }
    if (phone === "") {
      setPhoneError("Phone is required");
      setCommonError("Phone is required");

      return false;
    }
    if (phone.length > 0) {
      if (!phoneno.test(phone)) {
        setPhoneError("Invalid Phone Number");
        setCommonError("Invalid Phone Number");

        return false;
      }
    }

    if (password === "") {
      setPasswordError("Password is required");
      setCommonError("Password is required");

      return false;
    }
    if (password.length < 7) {
      setPasswordError("Password is too small");
      setCommonError("Password is too small");
      return false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      setCommonError("Passwords do not match");

      return false;
    }

    return true;
  };
  const goBack = () => {
    props.history.goBack();
  };

  const onClickCreateTeacher = () => {
    const isValid = validate();
    if (isValid) {
      dispatch(
        createTeacher({
          instituteId: selectedInstitute._id,
          name,
          email,
          phone,
          password,
        })
      );

      //console.log("is valid");
      setInstitute("");
      setName("");
      setEmail("");
      setPhone("");
      setPassword("")
      setConfirmPassword("")
    }
  };
  return (
    <div>
      <AppBar />

      <div style={{ padding: 16 }}>
        <Breadcrumbs />
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            // marginLeft: 40,
          }}
        >
          {/* <div style={{ width: 400 }}>
            {alert && (
              <Alert  icon={false} severity={alert.alertType}>{alert.message}</Alert>
            )}
          </div> */}

          {/* <Select
            options={insts}
            firstMenu="Select Institute"
            label="Institute"
            value={institute}
            onChange={handleInstituteChange}
            error={instituteError}
          /> */}
          <div style={{ padding: 8, marginBottom: 8, minHeight: 40, minWidth: 360, display: 'flex', alignItems: 'center', borderRadius: 4, background: "lightgray" }}>

            <Typography>Institute: {selectedInstitute.name}</Typography>
          </div>

          <CustomTextField
            label="Name"
            value={name}
            onChange={handleNameChange}
            error={nameError}
          />
          <CustomTextField
            label="Email"
            value={email}
            onChange={handleEmailChange}
            error={emailError}
          />
          <CustomTextField
            label="Phone Number"
            value={phone}
            onChange={handlePhoneNumberChange}
            error={phoneError}
          />
          <Typography style={{ marginTop: 16, display: 'flex', justifyContent: 'center', width: 360 }}>
            Please enter default password for the teacher
          </Typography>
          <CustomTextField
            label="Password"
            value={password}
            onChange={handlePasswordChange}
            error={passwordError}
          />
          <CustomTextField
            label="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={confirmPasswordError}
          />

          <div style={{ display: 'flex', justifyContent: "space-evenly" }}>

            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ margin: 8, minWidth: 170, height: 50 }}
              onClick={onClickCreateTeacher}
            >
              Create
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              style={{ margin: 8, minWidth: 170, height: 50 }}
              onClick={goBack}
            >
              Cancel
            </Button>
          </div>
          <div style={{ width: 360 }}>
            {alert && (
              <Alert icon={false} severity={alert.alertType}>{alert.message}</Alert>
            )}
            {commonError && (
              <Alert icon={false} severity="error" >{commonError}</Alert>
            )}
            {!commonError && (
              <div style={{ height: 48, width: 360, background: 'white' }} ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTeacher;
