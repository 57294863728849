import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import jwt_decode from "jwt-decode";
export const GET_ALL_INSTITUTES_REQUEST = "GET_ALL_INSTITUTES_REQUEST";
export const GET_ALL_INSTITUTES_FAILURE = "GET_ALL_INSTITUTES_FAILURE";
export const GET_ALL_INSTITUTES_SUCCESS = "GET_ALL_INSTITUTES_SUCCESS";
export const GET_INSTITUTE_DETAIL_REQUEST = "GET_INSTITUTE_DETAIL_REQUEST";
export const GET_INSTITUTE_DETAIL_FAILURE = "GET_INSTITUTE_DETAIL_FAILURE";
export const GET_INSTITUTE_DETAIL_SUCCESS = "GET_INSTITUTE_DETAIL_SUCCESS";

export const GET_INSTITUTE_SYLLABUS_REQUEST = "GET_INSTITUTE_SYLLABUS_REQUEST";
export const GET_INSTITUTE_SYLLABUS_FAILURE = "GET_INSTITUTE_SYLLABUS_FAILURE";
export const GET_INSTITUTE_SYLLABUS_SUCCESS = "GET_INSTITUTE_SYLLABUS_SUCCESS";


export const UPLOAD_INSTITUTE_LOGO_REQUEST = "UPLOAD_INSTITUTE_LOGO_REQUEST";
export const UPLOAD_INSTITUTE_LOGO_FAILURE = "UPLOAD_INSTITUTE_LOGO_FAILURE";
export const UPLOAD_INSTITUTE_LOGO_SUCCESS = "UPLOAD_INSTITUTE_LOGO_SUCCESS";
export const SET_INSTITUTE = "SET_INSTITUTE";
export const SET_SELECTED_INSTITUTE = "SET_SELECTED_INSTITUTE";

const timeout = 8000;
export const getAllInstitutesByMainInstituteId =
  (mainInstituteAdminId) => async (dispatch) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const token = localStorage.getItem("token");
    dispatch({
      type: GET_ALL_INSTITUTES_REQUEST,
    });
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          //Authorization: token,
        },
      };

      try {
        const res = await axios.get(
          `${baseUrl}/api/institute/institute-admin/${mainInstituteAdminId}`,
          config
        );
        //.then((res) => {
        if (res.status === 200) {
          //  console.log(res);
          dispatch({
            type: GET_ALL_INSTITUTES_SUCCESS,
            payload: res.data,
          });
        } else {
          console.log(res.response.data.message);
          dispatch({
            type: GET_ALL_INSTITUTES_FAILURE,
            payload: res.data,
          });
        }
        // });
      } catch (err) {
        clearTimeout(id);
        dispatch({
          type: GET_ALL_INSTITUTES_FAILURE,
          payload: err.response.data.message,
        });
      }
    }
  };
export const setInstitute = (instituteId) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  dispatch({
    type: SET_INSTITUTE,
    payload: instituteId,
  });
};
export const setSelectedInstitute = ({institute}) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  dispatch({
    type: SET_SELECTED_INSTITUTE,
    payload: institute,
  });
};


export const getInstituteDetail = (instituteId) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_INSTITUTE_DETAIL_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/institute/${instituteId}`,
        config
      );
      // console.log("inst detail" + res);
      if (res.status === 200) {
        // console.log("inst detail" + res);
        dispatch({
          type: GET_INSTITUTE_DETAIL_SUCCESS,
          payload: res.data.institute,
        });
      } else {
        dispatch({
          type: GET_INSTITUTE_DETAIL_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: GET_INSTITUTE_DETAIL_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getInstituteSyllabus = (instituteId) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_INSTITUTE_SYLLABUS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/institute/content/${instituteId}`,
        config
      );
      // console.log("inst detail" + res);
      if (res.status === 200) {
        dispatch({
          type: GET_INSTITUTE_SYLLABUS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_INSTITUTE_SYLLABUS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);

      dispatch({
        type: GET_INSTITUTE_SYLLABUS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const uploadInstituteLogo = ({ instituteId, imageFile,imageName }) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: UPLOAD_INSTITUTE_LOGO_REQUEST,
  });
console.log('upload inst image action');
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
      Accept: "*",
    },
  };
// console.log(imageName);
  const formData = new FormData();
  formData.append("imageFile", imageFile);
  formData.append("name", imageName);
  try {

    const res = await axios.put(
      `${baseUrl}/api/institute/update-profile-picture/${instituteId}`,
      formData,
      config
    );
    if (res.status == 200) {
      console.log(res);
      dispatch({
        type: UPLOAD_INSTITUTE_LOGO_SUCCESS,
        payload: res.data.message,
      });
    } else {
      dispatch({
        type: UPLOAD_INSTITUTE_LOGO_FAILURE,
        payload: res.data.message,
      });
      console.log('err'+res);
    }

  } catch (err) {
    clearTimeout(id);
    console.log(err);
    dispatch({
      type: UPLOAD_INSTITUTE_LOGO_FAILURE,
      payload: err.data.message,
    });
  }
};

