import {
  GET_MAIN_INSTITUTE_REQUEST,
  GET_MAIN_INSTITUTE_FAILURE,
  GET_MAIN_INSTITUTE_SUCCESS,
} from "../actions/MainInstitute";

const initialState = {
  mainInstitute: null,
  isLoading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MAIN_INSTITUTE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MAIN_INSTITUTE_SUCCESS:
      return {
        ...state,
        mainInstitute: action.payload,
        isLoading: false,
      };
    case GET_MAIN_INSTITUTE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
