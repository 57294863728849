import React, { useEffect } from "react";
import {
  List,
  ListItemSecondaryAction,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Typography,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Divider,
  Modal,
  ListItem,
  IconButton,
  Button,
  makeStyles,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WarningIcon from '@material-ui/icons/Warning';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  removeGroupTeacher,
  addTeacher,
  getInstituteTeachers,
} from "../store/actions/Teachers";
import Select from "./Select";
import { getGroupDetail } from "../store/actions/Groups";
import { useDispatch, useSelector } from "react-redux";
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
}));

const TeacherListItem = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const tchrs = useSelector((state) => state.teachers.teachers);
  const instituteId = useSelector((state) => state.institutes.instituteId);
  const groupId = useSelector((state) => state.groups.groupId);
  const group = useSelector((state) => state.groups.group);
  const [unAssignedTeachers, setUnAssignedTeachers] = React.useState([]);
  const [teacher, setTeacher] = React.useState("");
  const [removeTeacher, setRemoveTeacher] = React.useState("");
  const [removeTeacherSubject, setRemoveTeacherSubject] = React.useState("");
  const [removeTeacherObj, setRemoveTeacherObj] = React.useState({});
  const [selectTeacherError, setSelectTeacherError] = React.useState("");
  const [addTeacherSubject, setAddTeacherSubject] = React.useState("");

  const [open, setOpen] = React.useState(false); //remove teacher modal state
  const [isTeacherModalOpen, setIsTeacherModalOpen] = React.useState(false); // add teacher modal state

  const [modalStyle] = React.useState(getModalStyle);
  // console.log(props.teacher);
  // console.log(unAssignedTeachers);
  // console.log(removeTeacher);
  useEffect(() => {
    // dispatch(getInstituteTeachers(instituteId));
    let trs = []
    let filtered = tchrs.map(tcr => {
      let t = props.teacher.teachers.find(t => t._id == tcr._id)
      if (t) {
        tcr.isExists = true
      } else {
        tcr.isExists = false
      }
      return tcr
    })
    // console.log(filtered);
    filtered.map(ftcr => {
      if (!ftcr.isExists) {
        trs.push(ftcr)
      }
    })
    setUnAssignedTeachers(trs)
    console.log(trs);

  }, [instituteId, dispatch, props.teacher.teachers, tchrs]);

  const handleRemoveTeacherModalOpen = ({ teacherId, subjectId, teacher }) => {
    setOpen(true);
    setRemoveTeacher(teacherId);
    setRemoveTeacherSubject(subjectId);
    setRemoveTeacherObj(teacher)
    // console.log(props.teacher.teachers.find(t => t._id == teacherId).name);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleTeacherModalClose = (panel) => (event, isExpanded) => {
    setIsTeacherModalOpen(isExpanded ? panel : false);
  };
  const openAddTeacherModal = () => {
    setIsTeacherModalOpen(true)
  }
  const handleRemoveTeacher = () => {
    dispatch(
      removeGroupTeacher({
        teacherId: removeTeacher,
        subjectId: removeTeacherSubject,
        groupId: groupId,
      })
    );
    setOpen(false);
    setTimeout(() => {
      dispatch(getGroupDetail(groupId));
    }, 300);
  };
  const handleTeacherChange = (e) => {
    e.preventDefault();
    setTeacher([e.target.value]);
    setSelectTeacherError("")
  };
  const addSubjectTeacher = ({ subjectId }) => {
    if (!teacher) {
      setSelectTeacherError("Please select the teacher")
      return
    }
    let teacher_subject = {
      teacherId: unAssignedTeachers[teacher]._id,
      groupId,
      subjectId,
    };
    setIsTeacherModalOpen(false)
    dispatch(addTeacher(teacher_subject));
    setTeacher('')
    setTimeout(() => {
      dispatch(getGroupDetail(groupId));
    }, 300);
  };
  const addTeacherModalBody = (
    <div style={modalStyle} className={classes.paper}>
      {/* <IconButton edge="end" aria-label="delete">
        <PersonAddIcon color="primary" />
      </IconButton> */}
      <h3 id="simple-modal-title" style={{ margin: 8 }}>Assign Teacher</h3>
      <p id="simple-modal-description" style={{ margin: 8 }}>
        Please select the teacher

      </p>
      <p id="simple-modal-description" style={{ margin: 8 }}>

        Subject: {props.teacher.subject.name}
      </p>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Select
          options={unAssignedTeachers}
          firstMenu="Select Teacher"
          label="Teachers"
          value={teacher}
          onChange={handleTeacherChange}
        />

      </div>
      <p id="simple-modal-description" style={{ display: 'flex', flexGrow: 1 }}>
        {selectTeacherError && selectTeacherError}
      </p>

      <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
        <Button
          variant="contained"
          style={{ margin: 8, minWidth: 170, height: 50 }}
          color="primary"
          onClick={() => addSubjectTeacher({ subjectId: props.teacher.subject._id })}
        >
          Assign
        </Button>
        <Button
          variant="contained"
          style={{ margin: 8, minWidth: 170, height: 50 }}
          color="secondary"
          onClick={() => {
            setIsTeacherModalOpen(false)
            setSelectTeacherError("");
            setTeacher("");
          }}
        >
          Cancel
        </Button>
      </div>
    </div >
  );
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <IconButton edge="end" aria-label="delete">
        <WarningIcon color="secondary" />
      </IconButton>
      <h3 id="simple-modal-title" style={{ margin: 8 }}>Remove Teacher</h3>
      <p id="simple-modal-description" style={{ marginHorizontal: 8, }}>
        Do you want to remove the teacher?
        {/* Name : {removeTeacherObj.name}*/}
        {/* Subject :{props.subject.name} */}
      </p>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
        <p id="simple-modal-description" style={{ marginBottom: 0 }}>

          {/* Name: {removeTeacher && props.teacher.teachers.find(t => t._id == removeTeacher).name} */}

        </p>
        <p id="simple-modal-description" style={{ marginBottom: 0 }}>

          {/* Subject: {props.teacher.subject.name} */}
        </p>
      </div>

      <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
        <Button
          variant="contained"
          style={{ margin: 8, minWidth: 170, height: 50 }}
          color="primary"
          onClick={handleRemoveTeacher}
        >
          Remove
        </Button>
        <Button
          variant="contained"
          style={{ margin: 8, minWidth: 170, height: 50 }}
          color="secondary"
          onClick={() => {
            setOpen(false);

          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>{props.teacher.subject.name}</Typography>
        </AccordionSummary>
        <div>
          <List dense={false}>
            {props.teacher.teachers.map((tchr, i) => (
              <div key={i.toString()}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={tchr.imageUrl} />
                  </ListItemAvatar>
                  <ListItemText primary={tchr.name} />
                  <ListItemText primary={tchr.phoneNumber} />
                  <ListItemText primary={tchr.email} />
                  <ListItemSecondaryAction
                    onClick={() => {
                      handleRemoveTeacherModalOpen({
                        teacherId: tchr._id,
                        subjectId: props.teacher.subject._id,
                        teacher: tchr
                      });
                    }}
                  >
                    <IconButton edge="end" aria-label="unassign">
                      <RemoveCircleIcon color="secondary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>

                {/* <Divider variant="inset" component="li" /> */}
              </div>
            ))}
          </List>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
        <Modal
          open={isTeacherModalOpen}
          onClose={handleTeacherModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {addTeacherModalBody}
        </Modal>
        <div
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          {/* <Select
            options={tchrs}
            firstMenu="Select Teacher"
            label="Teachers"
            value={teacher}
            onChange={handleTeacherChange}
          /> */}
          {/* <Button
            variant="contained"
            disableElevation
            style={{ margin: 8, minWidth: 100 }}
            color="primary"
            onClick={() => {
              //addSubjectTeacher({ subjectId: props.teacher.subject._id });
              openAddTeacherModal()
            }}
          >
            Assign
          </Button> */}
          <IconButton edge="end" aria-label="assign" style={{ marginRight: '4px' }} onClick={() => {
            //addSubjectTeacher({ subjectId: props.teacher.subject._id });
            openAddTeacherModal()
          }} >
            <AddCircleIcon color="primary" />
          </IconButton>
        </div>
      </Accordion>
    </div>
  );
};

export default TeacherListItem;
