import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, List, ListItem, ListItemText,Typography } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import accountImage from "../assets/icons/account.png";
const useStyles = makeStyles({
  root: {
    maxWidth: 800,
    minWidth: 800,
    // minHeight: 300,
  },
  media: {
    height: 140,
    width: 140,
  },
});

const AccountItem = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {/* <div
        style={{
          justifyContent: "center",
          display: "flex",
          // width: 150,
          //height: 150,
          borderColor: "grey",
          borderWidth: 3,
        }}
      >
        <CardMedia
          className={classes.media}
          image={accountImage}

        />
      </div> */}
      <CardContent>
        <div
          style={{
            justifyContent: "space-around",
            flexDirection: "column",
            //display: "flex",
          }}
        >
           <List>
          {/* <ListItem selected={true}>
            <ListItemText primary="Account Details" />
          </ListItem> */}
        </List>
          <div
        style={{
          justifyContent: "flex-start",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <Typography style={{ minWidth: 200 }}>Name </Typography>
        <Typography style={{ marginLeft: 16 }}>{props.account !== null && props.account.name}</Typography>
      </div>
          <div
        style={{
          justifyContent: "flex-start",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <Typography style={{ minWidth: 200 }}>Email </Typography>
        <Typography style={{ marginLeft: 16 }}>{props.account !== null && props.account.email}</Typography>
      </div>
      <div
        style={{
          justifyContent: "flex-start",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <Typography style={{ minWidth: 200 }}>Phone Number </Typography>
        <Typography style={{ marginLeft: 16 }}>{props.account !== null && props.account.phoneNumber}</Typography>
      </div>
          <div
        style={{
          justifyContent: "flex-start",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <Typography style={{ minWidth: 200 }}>Institutes </Typography>
        <Typography style={{ marginLeft: 16 }}>{props.account !== null &&props.account.institutes.map(inst=>inst.name)}</Typography>
      </div>
        </div>
      </CardContent>
    </Card>
  );
};
export default AccountItem;
