import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import useParams from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { baseUrl } from "../config/baseUrl";
import { useSelector, useDispatch } from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import scienceImage from "../assets/images/content-provider.png";
import {
  CardMedia,
  Card,
  CardActionArea,
  Typography,
  Button,
  CardActions,
  CardContent,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 245,
    minWidth: 200,
    margin: 8, padding: 8
  },
  selectedCard: {
    maxWidth: 245,
    minWidth: 200,
    margin: 8, padding: 8,
    border: `2px solid green`,

  },
  media: {
    height: 100,
    width: 100,
    //borderRadius: 60,

  },
}));

const ContentProviderItem = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Card className={props.contentProviderId == props.selectedContentProviderId ? classes.selectedCard : classes.card} key={props.contentProviderId}
        // style={props.contentProviderId == props.selectedContentProviderId ? { background: "lightgreen", } : {}}
        onClick={props.onClick}
      >
        {/* <div>
      <Checkbox
        checked={props.contentProviderId == props.selectedContentProviderId?true:false}
        onChange={props.onClick}
        color="primary"
      />
      </div> */}
        <div style={{ justifyContent: "center", display: "flex", marginTop: 8 }}>
          <CardMedia
            className={classes.media}
            image={props.imageUrl ? props.imageUrl : scienceImage}

          />
        </div>
        <CardContent style={{ padding: 8, paddingBottom: 0 }}>
          <Typography
            style={{
              textAlign: "center",
            }}
            gutterBottom
            variant="subtitle1"
            component="h4"
          >
            {props.name}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default ContentProviderItem;
