import React from "react";
import { makeStyles, } from "@material-ui/core/styles";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StudentDetailItem from './StudentDetailItem'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {

  IconButton,

} from "@material-ui/core";
import { deleteStudent } from "../store/actions/Students";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));


const StudentListItem = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const onClickDeleteStudent = ({ studentId }) => {
    dispatch(deleteStudent({ studentId }))
  }
  return (
    <div className={classes.root}>
      <Accordion style={{ padding: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"

        >
          <Typography className={classes.heading}>{props.student.name}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <StudentDetailItem title="Email" answer={props.student.email} />
          <StudentDetailItem title="Phone" answer={props.student.phoneNumber} />
          <StudentDetailItem title="Parent Name" answer={props.student.parentName} />
          <StudentDetailItem title="Parent Email" answer={props.student.parentEmail} />
          <StudentDetailItem title="Parent Phone Number" answer={props.student.parentPhoneNumber} />

        </AccordionDetails>
        {/* <IconButton edge="end" aria-label="unassign" onClick={() => { onClickDeleteStudent({ studentId: props.student._id }) }}>
          <RemoveCircleIcon color="secondary" />
        </IconButton> */}

      </Accordion>


    </div>
  );
};

export default StudentListItem;
