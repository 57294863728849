import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  CssBaseline,
  Link,
  Box,
  Typography,
  makeStyles,
  Container,
  Card,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CustomTextField from "../components/CustomTextField";
import PasswordTextField from "../components/Password";

import { useDispatch, useSelector } from "react-redux";
import { verifyAccount } from "../store/actions/Account";
import AppBar from '../components/AppBar'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/impruapp.com">
        impruapp.com
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 80,
    padding: 64,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const VerifyAccount = (props) => {
  const classes = useStyles();
  const emailregexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const isVerified = useSelector((state) => state.account.isVerified);
  const [email, setemail] = useState("");
  const [code, setCode] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [emailError, setemailError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState("");

  if (isVerified) {
    return <Redirect to="/sing-in" />;
  }
  const handleEmailChange = (e) => {
    e.preventDefault();
    setemail(e.target.value);
    setemailError("");
  };
  const handleCodeChange = (e) => {
    e.preventDefault();
    setCode(e.target.value);
    setCodeError("");
  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    setpassword(e.target.value);
    setpasswordError("");
  };
  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    setconfirmPassword(e.target.value);
    setconfirmPasswordError("");
  };

  const validate = () => {
    if (email === "") {
      setemailError("Email is required");
      return false;
    }
    if (email.length > 0) {
      if (!emailregexp.test(email)) {
        setemailError("Invalid Email");
        return false;
      }
    }
    if (code === "") {
      setCodeError("Code is required");
      return false;
    }
    if (password === "") {
      setpasswordError("Password is Required");
      return false;
    }
    if (password.length < 8) {
      setpasswordError("Password is too small");
      return false;
    }
    if (confirmPassword === "") {
      setconfirmPasswordError("Confirm Password is Required");
      return false;
    }

    if (password !== confirmPassword) {
      setpasswordError("Passwords do not match");
      setconfirmPasswordError("Passwords do not match");
      return false;
    }
    return true;
  };

  const onClickVerifyAccount = () => {
    const isValid = validate();
    if (isValid) {
      //  console.log(email, code, password, confirmPassword);
      dispatch(verifyAccount({ email, code, password, confirmPassword }));
    }
  };

  return (
    <div> <AppBar />

      <div style={{ padding: 16 }}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Card style={{ borderRadius: 0 }} className={classes.paper}>
            <div style={{ minWidth: 300 }}>
              {account.message && (
                <Alert icon={false} severity="success">{account.message}</Alert>
              )}
              {account.error && <Alert icon={false}severity="error" >{account.error}</Alert>}
            </div>

            <Typography variant="h5">Verify Your Account</Typography>
            <CustomTextField
              label="Email"
              value={email}
              onChange={handleEmailChange}
              error={emailError}
            />

            <CustomTextField
              label="Verification Code"
              value={code}
              onChange={handleCodeChange}
              error={codeError}
            />
            <Typography variant="body1">Create your Password</Typography>
            <PasswordTextField
              label="Password"
              value={password}
              onChange={handlePasswordChange}
              error={passwordError}
            />
            <PasswordTextField
              label="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={confirmPasswordError}
            />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
              onClick={onClickVerifyAccount}
            >
              Verify Account
            </Button>
            <div style={{ textAlign: "center" }}>
              <Link href="/sign-in" variant="body2">
                Have an Account ? SignIn
              </Link>
            </div>
          </Card>

          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default VerifyAccount;
