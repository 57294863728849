import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";
import Breadcrumbs from "../components/Breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllContentProvidersByInstituteId,
  getBoardsByContentProviderId,
  getStandardsByBoardId,
  getCoursesByStandardId,
  getSubjectsByCourseId,
} from "../store/actions/InstituteContent";
import Select from "../components/Select";
import ContentProviderItem from "../components/InstituteContentProviderItem";
import BoardItem from "../components/InstituteBoardItem";
import StandardItem from "../components/InstituteStandardItem";
import CourseItem from "../components/InstituteCourseItem";
import SubjectItem from "../components/InstituteSubjectItem";
import AppBar from '../components/AppBar'

const InstituteContent = (props) => {
  const dispatch = useDispatch();
  const instituteId = useSelector((state) => state.institutes.instituteId);
  const selectedInstitute = useSelector((state) => state.institutes.selectedInstitute);
  const instituteContentProviders = useSelector(
    (state) => state.instituteContent.instituteContentProviders
  );
  // console.log(instituteContentProviders);
  const instituteBoards = useSelector(
    (state) => state.instituteContent.instituteBoards
  );
  const instituteStandards = useSelector(
    (state) => state.instituteContent.instituteStandards
  );
  const instituteCourses = useSelector(
    (state) => state.instituteContent.instituteCourses
  );
  const instituteSubjects = useSelector(
    (state) => state.instituteContent.instituteSubjects
  );
  const isLoading = useSelector((state) => state.instituteContent.isLoading);

  const [contentProvider, setContentProvider] = useState("");
  const [board, setBoard] = useState("");
  const [standard, setStandard] = useState("");
  const [course, setCourse] = useState("");

  const [contentProviderError, setContentProviderError] = useState("");
  const [boardError, setBoardError] = useState("");
  const [standardError, setStandardError] = useState("");
  const [courseError, setCourseError] = useState("");

  useEffect(() => {
    dispatch(getAllContentProvidersByInstituteId({ instituteId }));
  }, [dispatch, instituteId]);


  const onClickContentProvider = ({ contentProviderId }) => {
    console.log(contentProviderId);
    setContentProvider(contentProviderId);
    dispatch(getBoardsByContentProviderId({
      instituteId,
      contentProviderId
    }))
  }
  const onClickBoard = ({ contentProviderId, boardId }) => {
    setBoard(boardId);
    dispatch(getStandardsByBoardId({
      instituteId,
      boardId: boardId
    }))
  }
  const onClickStandard = ({ standardId, courseId }) => {
    setStandard(standardId);
    dispatch(getCoursesByStandardId({
      instituteId,
      standardId: standardId
    }))
  }
  const onClickCourse = ({ courseId, }) => {
    setCourse(courseId);
    dispatch(getSubjectsByCourseId({
      instituteId,
      courseId: courseId
    }))
  }

  const handleContentProviderChange = (e) => {
    e.preventDefault();
    // console.log("value" + e.target.value);
    if (e.target.value !== "") {
      setContentProvider([e.target.value]);
      dispatch(
        getBoardsByContentProviderId({
          instituteId,
          contentProviderId:
            instituteContentProviders[e.target.value].contentProviderId,
        })
      );
    }
  };

  const handleBoardChange = (e) => {
    e.preventDefault();

    if (e.target.value !== "") {
      setBoard([e.target.value]);
      dispatch(
        getStandardsByBoardId({
          instituteId,
          boardId: instituteBoards[e.target.value].boardId,
        })
      );
      setBoardError("");
      setStandard("");
      setCourse("");
    }
  };
  // console.log(standard);
  const handleStandardChange = (e) => {
    e.preventDefault();

    if (e.target.value !== "") {
      setStandard([e.target.value]);
      dispatch(
        getCoursesByStandardId({
          instituteId,
          standardId: instituteStandards[e.target.value].standardId,
        })
      );
      setStandardError("");
      setCourse("");
    }
  };
  const handleCourseChange = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setCourse([e.target.value]);
      dispatch(
        getSubjectsByCourseId({
          instituteId,
          courseId: instituteCourses[e.target.value].courseId,
        })
      );
      setCourseError("");
    }
  };
  return (
    <div>
      <AppBar />

      <div style={{ padding: 16 }}>
        <Breadcrumbs institute={selectedInstitute.name} />
        <div
          style={{
            //alignContent: "center",
            //alignItems: "center",
            justifyContent: "center",
            // alignSelf: "center",
            // display: "flex",
          }}
        >
          {/* <h5>Institute:{selectedInstitute.name}</h5> */}
          <List>
            <ListItem selected={true}>
              <ListItemText primary="Content Providers" />
            </ListItem>
          </List>
          <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
            {instituteContentProviders.length > 0 &&
              instituteContentProviders.map((cp, index) => (
                <ContentProviderItem
                  onClick={() => { onClickContentProvider({ contentProviderId: cp.contentProviderId }) }}
                  index={index}
                  contentProviderId={cp.contentProviderId}
                  selectedContentProviderId={contentProvider}
                  name={cp.name}
                  imageUrl={cp.imageUrl}
                />
              ))}
          </div>
          <Divider />
          <List>
            <ListItem selected={true}>
              <ListItemText primary="Boards" />
            </ListItem>
          </List>
          {/* <Select
            options={instituteContentProviders}
            firstMenu="Select Content Provider"
            label="Content Providers"
            value={contentProvider}
            onChange={handleContentProviderChange}
            error={contentProviderError}
          /> */}
          <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
            {instituteBoards.length > 0 &&
              instituteBoards.map((b) => (
                <BoardItem
                  onClick={() => { onClickBoard({ contentProviderId: b.contentProviderId, boardId: b.boardId }) }}
                  selectedBoardId={board}
                  boardId={b.boardId}
                  contentProviderId={b.contentProviderId}
                  name={b.name}
                  imageUrl={b.imageUrl}
                />
              ))}
          </div>
          <Divider />
          <List>
            <ListItem selected={true}>
              <ListItemText primary="Standards" />
            </ListItem>
          </List>
          {/* <Select
            options={instituteBoards}
            firstMenu="Select Board"
            label="Boards"
            value={board}
            onChange={handleBoardChange}
            error={boardError}
          /> */}
          <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
            {instituteStandards.length > 0 &&
              instituteStandards.map((i) => (
                <StandardItem
                  onClick={() => { onClickStandard({ boardId: i.boardId, standardId: i.standardId }) }}
                  selectedStandardId={standard}
                  standardId={i.standardId}
                  boardId={i.boardId}
                  name={i.name}
                  imageUrl={i.imageUrl}
                />
              ))}
          </div>
          <Divider />
          <List>
            <ListItem selected={true}>
              <ListItemText primary="Courses" />
            </ListItem>
          </List>
          {/* <Select
            options={instituteStandards}
            firstMenu="Select Standard"
            label="Standards"
            value={standard}
            onChange={handleStandardChange}
            error={standardError}
          /> */}
          <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
            {instituteCourses.length > 0 &&
              instituteCourses.map((i) => (
                <CourseItem
                  onClick={() => { onClickCourse({ courseId: i.courseId, standardId: i.standardId }) }}
                  selectedCourseId={course}
                  standardId={i.standardId}
                  // selectedCourseId={course}
                  courseId={i.courseId}
                  name={i.name}
                  imageUrl={i.imageUrl}
                />
              ))}
          </div>
          <Divider />
          <List>
            <ListItem selected={true}>
              <ListItemText primary="Subjects" />
            </ListItem>
          </List>
          {/* <Select
            options={instituteCourses}
            firstMenu="Select Course"
            label="Courses"
            value={course}
            onChange={handleCourseChange}
            error={courseError}
          /> */}
          <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
            {instituteSubjects.length > 0 &&
              instituteSubjects.map((i) => (
                <SubjectItem
                  subjectId={i.subjectId}

                  courseId={i.courseId}
                  name={i.name}
                  imageUrl={i.imageUrl}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstituteContent;
