import React, { useEffect } from "react";
import Drawer from "../components/Drawer";

import { Grid, Fab, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GroupItem from "../components/GroupItem";
import Select from "../components/Select";
import Breadcrumbs from "../components/Breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllInstitutesByMainInstituteId,
  setInstitute,
} from "../store/actions/Institutes";
import {
  getInstituteGroups,
  getGroupDetail,
  setGroup,
  setSelectedGroup
} from "../store/actions/Groups";
import AppBar from '../components/AppBar'

const Groups = (props) => {
  const dispatch = useDispatch();

  const [institute, setSelectedInstitute] = React.useState("");
  const selectedInstitute = useSelector((state) => state.institutes.selectedInstitute);
  const insts = useSelector((state) => state.institutes.institutes);
  const grps = useSelector((state) => state.groups.groups);
  const user = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.groups.isLoading);

  const handleInstituteChange = (e) => {
    e.preventDefault();
    setSelectedInstitute([e.target.value]);
    dispatch(getInstituteGroups(insts[e.target.value]._id));
    dispatch(setInstitute(insts[e.target.value]._id));
  };
  const onClickGetGroupDetail = ({ groupId, groupName, group }) => {
    dispatch(getGroupDetail(groupId));
    dispatch(setGroup(groupId));
    dispatch(setSelectedGroup({ group }));
    // console.log(group);
    props.history.push({
      pathname: `/Group Details`,
    });
  };

  useEffect(() => {
    // dispatch(getAllInstitutesByMainInstituteId(user.id)); //no filtering is applied
  }, [dispatch, user.id]);

  return (
    <div>
      <AppBar />

<div style={{padding:16}}>
        <Breadcrumbs institute={selectedInstitute.name}/>
        <div
          style={{
            // width: 800,
            //alignItems: "center",
            justifyContent: "space-around",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            //flexDirection: "column",
          }}
        >
          {/* <Select
            options={insts}
            minWidth={800}
            firstMenu="Select Institute"
            label="Institute"
            value={institute}
            onChange={handleInstituteChange}
          /> */}
          {/* <h5>Institute: {selectedInstitute.name}</h5> */}
          <div
            style={{
              // width: 800,
              flexGrow: 1,
              // marginLeft: 20,
            }}
          >
            <Grid
              container
              // direction="row"
              //justify="center"
              //alignItems="center"
              spacing={3}
            >
              {grps.length > 0 ? (
                grps.map((g, i) => (
                  <Grid
                    item  xs={12} sm={6} md={4} lg={2} xl={2} key={i.toString()}
                    
                  >
                    <GroupItem
                      onClick={() => {
                        onClickGetGroupDetail({ groupId: g._id, groupName: g.name, group: g });
                      }}
                      group={g}
                    />
                  </Grid>
                ))
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    width: 500,
                    height: 500,
                    backgroundColor: "whitesmoke",
                    marginTop: 16,
                    borderRadius: 8,
                    // alignItems: "center",
                  }}
                >
                  <h5
                    style={{
                      display: "flex",
                      // flex: 1,
                      justifyContent: "center",
                      //erticalAlign: "center",
                      //alignContent: "center",
                      alignItems: "center",
                      // marginTop: 300,
                    }}
                  >
                    Please select the institute from the
                    above dropdown
                  </h5>
                </div>
              )}
            </Grid>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            bottom: 0,
            right: 0,
            marginBottom: 16,
            marginRight: 16,
            position: "fixed",
          }}
        >
          <Tooltip title="New Group" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/Create Group");
              }}
            >
              <AddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Groups;
