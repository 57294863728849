import React, { useEffect } from "react";
import Select from "../components/Select";
import CustomTextField from "../components/CustomTextField";
import Breadcrumbs from "../components/Breadcrumbs";
import { Alert } from "@material-ui/lab";
import { makeStyles, Button, Typography, Checkbox, Modal, Card } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { createStudent } from "../store/actions/Students";
import { getInstituteGroups } from "../store/actions/Groups";
import {
  getAllInstitutesByMainInstituteId,
} from "../store/actions/Institutes";
import AppBar from '../components/AppBar'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: 600,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: "yellow",
    // paddingBottom: 80,
    // paddingTop: 40,
    padding: 16,
    display: "flex",
    margin: 8,
    // border: `2px solid red`,
  },
  passwordContainer: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: "yellow",
    // paddingBottom: 80,
    // paddingTop: 40,
    padding: 16,
    display: "flex",
    margin: 8,
    // border: `2px solid red`,
  }
}));
function rand() {
  return Math.round(Math.random() * 20) - 10;
}


function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const CreateStudent = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const insts = useSelector((state) => state.institutes.institutes);
  const selectedInstitute = useSelector((state) => state.institutes.selectedInstitute);
  const selectedGroup = useSelector((state) => state.groups.selectedGroup);


  const grps = useSelector((state) => state.groups.groups);
  const message = useSelector((state) => state.students.message);
  const error = useSelector((state) => state.students.error);
  const user = useSelector((state) => state.auth.user);
  const alert = useSelector((state) => state.alert.alert);
  const [institute, setInstitute] = React.useState("");
  const [group, setGroup] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [isParentExists, setIsParentExists] = React.useState(true);
  const [parentName, setParentName] = React.useState("");
  const [parentEmail, setParentEmail] = React.useState("");
  const [parentPhone, setParentPhone] = React.useState("");

  const [commonError, setCommonError] = React.useState("");

  const [instituteError, setInstituteError] = React.useState("");
  const [groupError, setGroupError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  const [parentNameError, setParentNameError] = React.useState("");
  const [parentEmailError, setParentEmailError] = React.useState("");
  const [parentPhoneError, setParentPhoneError] = React.useState("");

  useEffect(() => {
    dispatch(getAllInstitutesByMainInstituteId(user.id));
  }, [dispatch, user.id]);

  const handleInstituteChange = (e) => {
    e.preventDefault();

    setInstitute([e.target.value]);
    dispatch(getInstituteGroups(insts[e.target.value]._id));
    setInstituteError("");
    setGroup("");
    setGroupError("");
  };

  const handleGroupChange = (e) => {
    e.preventDefault();
    setGroup([e.target.value]);
    setGroupError("");
    setCommonError("")

  };
  const handleNameChange = (e) => {
    e.preventDefault();

    setName(e.target.value);
    setNameError("");
    setCommonError("")

  };
  const handleEmailChange = (e) => {
    e.preventDefault();

    setEmail(e.target.value);
    setEmailError("");
    setCommonError("")

  };

  const handlePhoneNumberChange = (e) => {
    e.preventDefault();

    setPhone(e.target.value);
    setPhoneError("");
    setCommonError("")

  };

  const handleParentNameChange = (e) => {
    e.preventDefault();

    setParentName(e.target.value);
    setParentNameError("");
    setCommonError("")

  };
  const handleParentEmailChange = (e) => {
    e.preventDefault();

    setParentEmail(e.target.value);
    setParentEmailError("");
    setCommonError("")

  };

  const handleParentPhoneNumberChange = (e) => {
    e.preventDefault();

    setParentPhone(e.target.value);
    setParentPhoneError("");
    setCommonError("")

  };
  const handleParentCheckBoxChange = (event) => {
    setIsParentExists(event.target.checked);
  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setPassword(e.target.value);
    setPasswordError("");
    setCommonError("")

  };
  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
    setCommonError("")

  };
  const goBack = () => {
    props.history.goBack();
  };

  const validate = () => {
    // if (institute === "") {
    //   setInstituteError("Select Institute");
    //   setCommonError("Select Institute");

    //   return false;
    // }
    // if (group === "") {
    //   setGroupError("Select Group");
    //   setCommonError("Select Group");

    //   return false;
    // }
    if (name === "") {
      setNameError("Name is required");
      setCommonError("Name is required");

      return false;
    }

    if (email === "") {
      setEmailError("Email is required");
      setCommonError("Email is required");

      return false;
    }
    if (email.length > 0) {
      if (!regexp.test(email)) {
        setEmailError("Invalid Email");
        setCommonError("Invalid Email");

        return false;
      }
    }
    if (phone === "") {
      setPhoneError("Phone is required");
      setCommonError("Phone is required");

      return false;
    }
    if (phone.length > 0) {
      if (!phoneno.test(phone)) {
        setPhoneError("Invalid Phone Number");
        setCommonError("Invalid Phone Number");

        return false;
      }
    }
    if (isParentExists && parentName === "") {
      setParentNameError("Name is required");
      setCommonError("Name is required");

      return false;
    }

    if (isParentExists && parentEmail === "") {
      setParentEmailError("Parent email is required");
      setCommonError("Parent Email is required");

      return false;
    }
    if (isParentExists && parentEmail.length > 0) {
      if (!regexp.test(email)) {
        setParentEmailError("Invalid parent email");
        setCommonError("Invalid parent email");

        return false;
      }
    }
    if (isParentExists && parentPhone === "") {
      setParentEmailError("Parent phone is required");
      setCommonError("Parent phone is required");

      return false;
    }
    if (isParentExists && parentPhone.length > 0) {
      if (!phoneno.test(phone)) {
        setParentPhoneError("Invalid Phone Number");
        setCommonError("Invalid Phone Number");

        return false;
      }
    }

    if (password == "") {
      setPasswordError("Password is required");
      setCommonError("Password is required");

      return false;
    } if (password.length < 7) {
      setPasswordError("Password is too small");
      setCommonError("Password is too small");

      return false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      setCommonError("Passwords do not match");

      return false;
    }

    return true;
  };
  const onClickCreateStudent = () => {
    const isValid = validate();
    if (isValid) {
      const parentDetails = {
        name: parentName,
        email: parentEmail,
        phoneNumber: parentPhone,
      };
      const std = {
        instituteId: selectedInstitute._id,
        groupId: selectedGroup._id,
        name,
        email,
        phoneNumber: phone,
        password,
        parentDetails: parentDetails,
        isParentExists,
      };
      dispatch(createStudent(std));
      setInstitute("");
      setGroup("");
      setName("");
      setEmail("");
      setPhone("");
      setParentName("");
      setParentEmail("");
      setParentPhone("");
      setPassword("")
      setConfirmPassword("")
      setCommonError("")
    }
  };

  return (
    <div>
      <AppBar />

      <div style={{ padding: 16 }}>
        <Breadcrumbs />
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            // marginLeft: 40,
          }}
        >
          {/* <div style={{ width: 400 }}>

            {alert && (
              <Alert icon={false} severity={alert.alertType}>{alert.message}</Alert>
            )}
          </div> */}

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginLeft: 16 }}>
              {/* <Select
                options={insts}
                firstMenu="Select Institute"
                label="Institutes"
                value={institute}
                onChange={handleInstituteChange}
                error={instituteError}
              /> */}
              <div style={{ padding: 8, marginBottom: 8, minHeight: 40, minWidth: 360, display: 'flex', alignItems: 'center', borderRadius: 4, background: "lightgray" }}>

                <Typography>Institute: {selectedInstitute.name}</Typography>
              </div>
            </div>
            <div style={{ marginLeft: 56 }}>
              {/* <Select
                options={grps}
                firstMenu="Select Group"
                label="Groups"
                value={group}
                onChange={handleGroupChange}
                error={groupError}
              /> */}
              <div style={{ padding: 8, marginBottom: 8, minHeight: 40, minWidth: 360, display: 'flex', alignItems: 'center', borderRadius: 4, background: "lightgray" }}>

                <Typography>Group: {selectedGroup.name}</Typography>
              </div>
            </div>

          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Card style={{ borderRadius: 0 }} className={classes.container}>
              <Typography style={{ width: 360 }}>
                Student Details
              </Typography>
              <CustomTextField
                label="Name"
                value={name}
                onChange={handleNameChange}
                error={nameError}
              />
              <CustomTextField
                label="Email"
                value={email}
                onChange={handleEmailChange}
                error={emailError}
              />
              <CustomTextField
                label="Phone Number"
                value={phone}
                onChange={handlePhoneNumberChange}
                error={phoneError}
              />
            </Card>


            {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              // alignContent: "center",
              //textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              // alignSelf: "center",
              // margin: 8,
              marginTop: 16,
            }}
          >
            <Typography style={{ marginLeft: 8 }}>
              Do you want to add parent details, please click here
            </Typography>
            <Checkbox
              style={{}}
              checked={isParentExists}
              onChange={handleParentCheckBoxChange}
              color="primary"
            />
          </div> */}
            {isParentExists && (
              <Card style={{ borderRadius: 0 }} className={classes.container}>
                <div>
                  <Typography style={{ width: 360 }}>
                    Parent Details
                  </Typography>
                  <CustomTextField
                    label="Parent Name"
                    value={parentName}
                    onChange={handleParentNameChange}
                    error={parentNameError}
                  />
                  <CustomTextField
                    label="Parent Email"
                    value={parentEmail}
                    onChange={handleParentEmailChange}
                    error={parentEmailError}
                  />
                  <CustomTextField
                    label="Parent Phone Number"
                    value={parentPhone}
                    onChange={handleParentPhoneNumberChange}
                    error={parentPhoneError}
                  />
                </div>
              </Card>
            )}

          </div>
          <Card style={{ borderRadius: 0 }} className={classes.passwordContainer}>

            <Typography style={{ width: 360, alignSelf: 'flex-start' }}>
              {isParentExists
                ? " Please enter default password for the student and parent"
                : " Please enter default password for the student"}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <CustomTextField
                label="Password"
                value={password}
                onChange={handlePasswordChange}
                error={passwordError}
              />
              <div style={{ marginLeft: 48 }}>
                <CustomTextField
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  error={confirmPasswordError}
                />
              </div>
            </div>

          </Card>
          <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ margin: 8, minWidth: 170, height: 50 }}
              onClick={onClickCreateStudent}
            >
              Create
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              style={{ margin: 8, minWidth: 170, height: 50 }}
              onClick={goBack}
            >
              Cancel
            </Button>
          </div>
          <div style={{ width: 360 }}>

            {alert && (
              <Alert icon={false} severity={alert.alertType}>{alert.message}</Alert>
            )}
            {commonError && (
              <Alert icon={false} severity="error" >{commonError}</Alert>
            )}
            {!commonError && (
              <div style={{ height: 48, width: 360, background: 'white' }} ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateStudent;
