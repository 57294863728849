import React from "react";
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
import PageNotFound from "../assets/images/page-not-found.png";
import AppBar from '../components/AppBar'
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${PageNotFound})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    // backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

export default function SignInSide() {
  const classes = useStyles();

  return (
    <div>
      <AppBar />

      <div style={{ padding: 16 }}>

        <Grid container component="main" className={classes.root}>
          {/* <CssBaseline /> */}
          <Grid item xs={12} sm={12} md={12} xl={12} className={classes.image} />
        </Grid>
      </div>
    </div>
  );
}
