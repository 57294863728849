import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import Typography from "@material-ui/core/Typography";
import InstituteDetail from "../components/InstituteDetail";

import {
  Paper,
  Grid,
  makeStyles,
  CssBaseline,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import Breadcrumbs from "../components/Breadcrumbs";
import { useSelector } from "react-redux";
import AppBar from '../components/AppBar'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const InstituteDetailPage = (props) => {
  const classes = useStyles();
  const institute = useSelector((state) => state.institutes.institute);
  const isLoading = useSelector((state) => state.institutes.isLoading);
  const selectedInstitute = useSelector((state) => state.institutes.selectedInstitute);
  useEffect(() => {
    if (institute == null && isLoading == false) {
       props.history.push("/Institutes");
    }
  }, [institute]);
  return (
    <div>
   <AppBar />

<div style={{padding:16}}>
        <Breadcrumbs institute={selectedInstitute.name}/>
        <div
          style={{
            //alignContent: "center",
            //alignItems: "center",
            justifyContent: "center",
            // alignSelf: "center",
            // display: "flex",
          }}
        >
          <div>
            <InstituteDetail
              institute={institute !==null && institute}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstituteDetailPage;
