import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import XLSX from "xlsx";
import Select from "../components/Select";
import CustomTextField from "../components/CustomTextField";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import Breadcrumbs from "../components/Breadcrumbs";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { createMultipleStudents } from "../store/actions/Students";
import { getInstituteGroups } from "../store/actions/Groups";
import { getAllInstitutesByMainInstituteId } from "../store/actions/Institutes";
import AppBar from '../components/AppBar'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const CreateMultipleStudents = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const insts = useSelector((state) => state.institutes.institutes);
  const grps = useSelector((state) => state.groups.groups);
  const message = useSelector((state) => state.students.message);
  const error = useSelector((state) => state.students.error);
  const alert = useSelector((state) => state.alert.alert);
  const selectedInstitute = useSelector((state) => state.institutes.selectedInstitute);
  const selectedGroup = useSelector((state) => state.groups.selectedGroup);


  const [institute, setInstitute] = React.useState("");
  const [group, setGroup] = React.useState("");
  const [students, setStudents] = React.useState([]);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [commonError, setCommonError] = React.useState("");
  const [instituteError, setInstituteError] = React.useState("");
  const [groupError, setGroupError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  useEffect(() => {
    dispatch(getAllInstitutesByMainInstituteId(user.id));
  }, [dispatch, user.id]);

  const handleInstituteChange = (e) => {
    e.preventDefault();

    setInstitute([e.target.value]);
    dispatch(getInstituteGroups(insts[e.target.value]._id));
    setInstituteError("");
    setGroup("");
    setGroupError("");
    setCommonError("")
  };

  const handleGroupChange = (e) => {
    e.preventDefault();
    setGroup([e.target.value]);
    setGroupError("");
    setCommonError("")

  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setPassword(e.target.value);
    setPasswordError("");
    setCommonError("")

  };
  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
    setCommonError("")

  };
  const goBack = () => {
    props.history.goBack();
  };

  const validate = () => {
    // if (institute === "") {
    //   setInstituteError("Select Institute");
    //   setCommonError("Select Institute");
    //   return false;
    // }
    // if (group === "") {
    //   setGroupError("Select Group");
    //   setCommonError("Select Group");
    //   return false;
    // }

    if (password == "") {
      setPasswordError("Password is required");
      setCommonError("Password is required");
      return false;
    }
    if (password.length < 7) {
      setPasswordError("Password is too small");
      setCommonError("Password is too small");
      return false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      setCommonError("Passwords do not match");

      return false;
    }
    return true;
  };

  const handleFileUpload = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws);

      let newData = dataParse.map((rec) => {
        let obj = {
          //  can create custom object
          name: rec.name,
          phoneNumber: rec.phoneNumber,
          email: rec.email,
          parentName: rec.parentName,
          parentEmail: rec.parentEmail,
          parentPhoneNumber: rec.parentPhoneNumber
        };
        return obj;
      });
      //console.log(dataParse);
      setStudents(newData);
    };
    reader.readAsBinaryString(f);
  };
  const onClickCreateMultipleStudents = () => {
    const isValid = validate();
    if (isValid) {
      const std = {
        instituteId: selectedInstitute._id,
        groupId: selectedGroup._id,
        students,
        password,
      };
      dispatch(createMultipleStudents(std));
      setInstitute("");
      setGroup("");
      setPassword("")
      setConfirmPassword("")
    }
  };

  return (
    <div>
      <AppBar />

      <div style={{ padding: 16 }}>
        <Breadcrumbs />

        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "row",
            // marginLeft: 40,
          }}
        >
          <div
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              // alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              // marginLeft: 40,
            }}
          >
            {/* <div style={{ width: 400 }}>

              {alert && (
                <Alert icon={false} severity={alert.alertType}>{alert.message}</Alert>
              )}
            </div> */}

            {/* <Select
              options={insts}
              firstMenu="Select Institute"
              label="Institutes"
              value={institute}
              onChange={handleInstituteChange}
              error={instituteError}
            /> */}
            <div style={{ padding: 8, marginBottom: 8, minHeight: 40, minWidth: 360, display: 'flex', alignItems: 'center', borderRadius: 4, background: "lightgray" }}>

              <Typography>Institute: {selectedInstitute.name}</Typography>
            </div>

            {/* <Select
              options={grps}
              firstMenu="Select Group"
              label="Groups"
              value={group}
              onChange={handleGroupChange}
              error={groupError}
            /> */}
            <div style={{ padding: 8, marginBottom: 8, minHeight: 40, minWidth: 360, display: 'flex', alignItems: 'center', borderRadius: 4, background: "lightgray" }}>
              <Typography>Group: {selectedGroup.name}</Typography>
            </div>
            <Typography style={{ marginTop: 16, display: 'flex', justifyContent: 'center', width: 360 }}>
              Upload Students using the provided template file
            </Typography>
            <div
              style={{
                margin: 8,
                display: "flex",
                flexDirection: "row",
              }}
            >
              {/* <input
                type="file"
                required
                accept=".xlsx"
                onChange={handleFileUpload}
              />
              <Typography variant="body1">
                {students.length > 0 && students.length}{" "}
                {students.length > 0 && "Students"}{" "}
              </Typography> */}

              <label for="file-upload" class="custom-file-upload">
                <i class="fa fa-cloud-upload"></i> <i class="fa fa-cloud-upload"></i>{students.length == 0 ? "Upload Student's File" : "Change Uploaded File - "} {students.length > 0 && students.length}
              </label>
              <input id="file-upload" type="file" accept=".xlsx"
                onChange={handleFileUpload} />
              {/* <Typography variant="body2" style={{ alignItems: 'center', display: 'flex', marginLeft: 16 }}>
                {students.length > 0 && students.length}{" "}
                {students.length > 0 && "Students"}{" "}
              </Typography> */}
            </div>
            <Typography style={{ marginTop: 16, display: 'flex', justifyContent: 'center', width: 360, textAlign: "center" }}>
              Note: The students of the selected file will be added to the above selected group
            </Typography>

            <Typography style={{ marginTop: 16, display: 'flex', justifyContent: 'center', width: 360 }}>
              Please enter default password for the users
            </Typography>
            <CustomTextField
              label="Password"
              value={password}
              onChange={handlePasswordChange}
              error={passwordError}
            />
            <CustomTextField
              label="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={confirmPasswordError}
            />

            <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ margin: 8, minWidth: 170, height: 50 }}
                onClick={onClickCreateMultipleStudents}
              >
                Create
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{ margin: 8, minWidth: 170, height: 50 }}
                onClick={goBack}
              >
                Cancel
              </Button>
            </div>
            <div style={{ width: 360 }}>

              {alert && (
                <Alert icon={false} severity={alert.alertType}>{alert.message}</Alert>
              )}
              {commonError && (
                <Alert icon={false} severity="error" >{commonError}</Alert>
              )}
              {!commonError && (
                <div style={{ height: 48, width: 360, background: 'white' }} ></div>
              )}
            </div>
          </div>
          <div
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ marginTop: 8, marginLeft: 16 }}>
              {/* <Typography>
                Click here to download sample template
              </Typography> */}

              Click <a href="/students-template.xlsx" download>
                here
              </a> to download sample template

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMultipleStudents;
