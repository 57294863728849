import React, { useEffect, useState } from "react";
import Select from "../components/Select";
import CustomTextField from "../components/CustomTextField";
import SubjectItem from "../components/SubjectItem";
import Breadcrumbs from "../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  makeStyles,
  Grid,
  Button,
  Divider,
  Typography,
  TextField,
  Modal
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { getAllInstitutesByMainInstituteId } from "../store/actions/Institutes";
import { createGroup } from "../store/actions/Groups";
import {
  getAllContentProvidersByInstituteId,
  getBoardsByContentProviderId,
  getStandardsByBoardId,
  getCoursesByStandardId,
  getSubjectsByCourseId,
} from "../store/actions/InstituteContent";
import AppBar from '../components/AppBar'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "absolute",
    width: 600,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    margin: theme.spacing(1),
  },
  multiText: {
    "& > *": {
      margin: theme.spacing(1),
      width: "400px",
    },
  }
}));
function rand() {
  return Math.round(Math.random() * 20) - 10;
}


function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const NewGroup = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const instituteContentProviders = useSelector(
    (state) => state.instituteContent.instituteContentProviders
  );
  const instituteBoards = useSelector(
    (state) => state.instituteContent.instituteBoards
  );
  const instituteStandards = useSelector(
    (state) => state.instituteContent.instituteStandards
  );
  const instituteCourses = useSelector(
    (state) => state.instituteContent.instituteCourses
  );
  const instituteSubjects = useSelector(
    (state) => state.instituteContent.instituteSubjects
  );

  const insts = useSelector((state) => state.institutes.institutes);
  const groupError = useSelector((state) => state.groups.error);
  const groupMessage = useSelector((state) => state.groups.message);
  const alert = useSelector((state) => state.alert.alert);
  const [institute, setInstitute] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [contentProvider, setContentProvider] = useState("");
  const [board, setBoard] = useState("");
  const [standard, setStandard] = useState("");
  const [course, setCourse] = useState("");
  const [subjects, setSubjects] = useState([]);

  const [commonError, setCommonError] = useState("");
  const [instError, setInstError] = useState("");
  const [contentProviderError, setContentProviderError] = useState("");
  const [boardError, setBoardError] = useState("");
  const [standardError, setStandardError] = useState("");
  const [courseError, setCourseError] = useState("");
  const [nameError, setnameError] = useState("");
  const [subjectsError, setSubjectsError] = useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(getAllInstitutesByMainInstituteId(user.id));
    setSubjects(instituteSubjects);
  }, [dispatch, user.id, instituteSubjects]);

  const handleInstituteChange = (e) => {
    e.preventDefault();

    if (e.target.value !== "") {
      setInstitute([e.target.value]);
      dispatch(
        getAllContentProvidersByInstituteId({
          instituteId: insts[e.target.value]._id,
        })
      );
      setCommonError("")
      setInstError("");
      setBoard("");
      setStandard("");
      setCourse("");

      setSubjects([]);
    }
  };

  const handleGroupNameChange = (e) => {
    setName(e.target.value);
    setnameError("");
    setCommonError("")
  };
  const handleDescriptionChange = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };
  const handleContentProviderChange = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setContentProvider([e.target.value]);
      dispatch(
        getBoardsByContentProviderId({
          instituteId: insts[institute]._id,
          contentProviderId:
            instituteContentProviders[e.target.value].contentProviderId,
        })
      );
      setContentProviderError("");
      setBoardError("");
      setCommonError("")
      setStandard("");
      setCourse("");
      setSubjects([]);
    }
  };
  const handleBoardChange = (e) => {
    e.preventDefault();

    if (e.target.value !== "") {
      setBoard([e.target.value]);
      dispatch(
        getStandardsByBoardId({
          instituteId: insts[institute]._id,
          boardId: instituteBoards[e.target.value].boardId,
        })
      );
      setBoardError("");
      setCommonError("")
      setStandard("");
      setCourse("");
      setSubjects([]);
    }
  };
  const handleStandardChange = (e) => {
    e.preventDefault();

    if (e.target.value !== "") {
      setStandard([e.target.value]);
      dispatch(
        getCoursesByStandardId({
          instituteId: insts[institute]._id,
          standardId: instituteStandards[e.target.value].standardId,
        })
      );
      setStandardError("");
      setCommonError("")
      setCourse("");
      setSubjects([]);
    }
  };
  const handleCourseChange = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setCourse([e.target.value]);
      dispatch(
        getSubjectsByCourseId({
          instituteId: insts[institute]._id,
          courseId: instituteCourses[e.target.value].courseId,
        })
      );
      setCourseError("");
      setCommonError("")
    }
  };

  const onSelectSubjects = (sub) => {
    const subs = subjects.map((s) => {
      if (sub._id === s._id) {
        s.isSelected = !s.isSelected;
      }
      return s;
    });
    setSubjects(subs);
    setSubjectsError("");
    setCommonError("")
  };
  const validate = () => {
    if (name === "") {
      setCommonError("Group name is required");
      setnameError("Group name is required");
      return false;
    }
    if (institute === "") {
      setCommonError("Institute  is required");
      setInstError("Institute  is required");
      return false;
    }
    if (contentProvider === "") {
      setCommonError("Content Provider  is required");
      setContentProviderError("Content Provider  is required");
      return false;
    }
    if (board === "") {
      setCommonError("Board  is required");
      setBoardError("Board  is required");
      return false;
    }

    if (standard === "") {
      setCommonError("Standard is required");
      setStandardError("Standard is required");
      return false;
    }

    if (course === "") {
      setCommonError("Course is required");
      setCourseError("Course is required");
      return false;
    }

    let subIds = [];
    if (subjects) {
      subjects.map((sub) => {
        if (sub.isSelected === true) {
          subIds.push(sub._id);
        }
      });
    }
    if (subIds.length <= 0) {
      setCommonError("Select at least one subject");
      setSubjectsError("Select at least one subject");
      return false;
    }

    return true;
  };
  const onClickCreateGroupAlert = () => {
    const isValid = validate();
    if (isValid) {
      setOpen(true)
    }
  }
  const goBack = () => {
    props.history.goBack();
  };
  const createNewGroup = () => {
    let subIds = [];

    const isValid = validate();

    if (isValid) {
      let instituteId = insts[institute]._id; // this inside isValied block, otherwise give error

      if (subjects) {
        subjects.map((sub) => {
          if (sub.isSelected === true) {
            subIds.push(sub.subjectId);
          }
        });
      }

      const grp = {
        instituteId,
        name,
        description,
        boardId: instituteBoards[board].boardId,
        standardId: instituteStandards[standard].standardId,
        courseId: instituteCourses[course].courseId,
        subjectIds: subIds,
      };

      dispatch(createGroup(grp));
      setInstitute("");
      setName("");
      setDescription("");
      setBoard("");
      setStandard("");
      setCourse("");
      setSubjects([]);
      setOpen(false)
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h3 style={{ margin: 8 }}>Create Group</h3>
      <p id="simple-modal-description" style={{ margin: 8 }}>
        Are you sure want to create the group?
      </p>
      <div id="simple-modal-description" style={{ display: 'flex', flexGrow: 1, flexDirection: "column" }}>
        <p style={{ marginBottom: 0 }}>
          Institute: {institute && insts[institute].name}
        </p>
        <p style={{ marginBottom: 0 }}>
          Group: {name}
        </p>
        <p style={{ marginBottom: 0 }}>
          Class: {standard && instituteStandards[standard].name}
        </p>
        <p style={{ marginBottom: 0 }}>
          Subjects: {subjects.map(s => {
            if (s.isSelected == true) {
              return `${s.name}, `
            }
          })}
        </p>
      </div>
      <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
        <Button
          variant="contained"
          style={{ margin: 8, minWidth: 170, height: 50 }}
          color="primary"

          onClick={createNewGroup}
        >
          Create
        </Button>
        <Button
          variant="contained"
          style={{ margin: 8, minWidth: 170, height: 50 }}
          color="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>

      </div>
    </div>
  );
  return (
    <div>
      <AppBar />

      <div style={{ padding: 16 }}>
        <Breadcrumbs />
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            //marginLeft: 40,
          }}
        >

          {/* <div style={{ width: 400 }}>
            {alert && (
              <Alert icon={false} severity={alert.alertType}>{alert.message}</Alert>
            )}
          </div> */}
          <CustomTextField
            label="Group Name"
            value={name}
            onChange={handleGroupNameChange}
            error={nameError}
          />

          <form className={classes.multiText} noValidate autoComplete="off">
            <TextField
              label="Description"
              variant="outlined"
              color="primary"
              multiline
              rows={4}
              value={description}
              onChange={handleDescriptionChange}
              style={{ width: 360 }}
            />
          </form>

          <Select
            options={insts}
            firstMenu="Select Institute"
            label="Institute"
            value={institute}
            onChange={handleInstituteChange}
            error={instError}
          />
          <Select
            options={instituteContentProviders}
            firstMenu="Select Content Provider"
            label="Content Provider"
            value={contentProvider}
            onChange={handleContentProviderChange}
            error={contentProviderError}
          />
          <Select
            options={instituteBoards}
            firstMenu="Select Board"
            label="Board"
            value={board}
            onChange={handleBoardChange}
            error={boardError}
          />
          <Select
            options={instituteStandards}
            firstMenu="Select Standard"
            label="Standard"
            value={standard}
            onChange={handleStandardChange}
            error={standardError}
          />
          <Select
            options={instituteCourses}
            firstMenu="Select Course"
            label="Course"
            value={course}
            onChange={handleCourseChange}
            error={courseError}
          />

          <Divider />
          <div style={{ marginLeft: 8, marginTop: 16 }}>
            <Typography variant="h6" component="h5">
              Subjects
            </Typography>
            <Typography color="textSecondary" variant="body2" gutterBottom>
              Please select the subject. Only the selected subjects will be given access
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              // flex: 1,
              alignItems: "flex-start",
              justifyContent: "space-around",
            }}
          >
            <Grid
              //style={{ display: "flex", flex: 1 }}
              container
              // justify="center"
              //alignItems="center"
              spacing={2}
            >
              {subjects.length > 0 ? (
                subjects.map((sub, i) => (
                  <Grid
                    key={i.toString()}
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                      justifyContent: "center",
                      //alignContent: "center",
                      textAlign: "center",
                    }}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={6}
                  // spacing={2}
                  >
                    <SubjectItem
                      onChange={() => onSelectSubjects(sub)}
                      subject={sub}
                    />
                  </Grid>
                ))
              ) : (
                <div
                  style={{
                    // flex: 1,
                    display: "flex",

                    width: 360,
                    height: 100,
                    backgroundColor: "whitesmoke",
                    margin: 16,
                    borderRadius: 8,
                    alignItems: "center",
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "center",
                      padding: 16
                    }}
                  >
                    Please select the course
                  </Typography>
                </div>
              )}
            </Grid>
          </div>
          {/* <Typography
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
            // className={classes.formControl}
            variant="body2"
            color="secondary"
          >
            {subjectsError}
          </Typography> */}

          <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onClickCreateGroupAlert}
              style={{ margin: 8, minWidth: 170, height: 50 }}
            >
              Create
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              style={{ margin: 8, minWidth: 170, height: 50 }}
              onClick={goBack}
            >
              Cancel
            </Button>
          </div>
          <div style={{ width: 360 }}>

            {alert && (
              <Alert icon={false} severity={alert.alertType}>{alert.message}</Alert>
            )}
            {commonError && (
              <Alert icon={false} severity="error" >{commonError}</Alert>
            )}
            {!commonError && (
              <div style={{ height: 48, width: 360, background: 'white' }} ></div>
            )}
          </div>
          <Modal
            open={open}
            onClose={() => { setOpen(false) }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default NewGroup;
