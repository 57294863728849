import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { baseUrl } from "../config/baseUrl";
import { useSelector } from "react-redux";

import scienceImage from "../assets/images/institute.jpg";
import {
  CardMedia,
  Card,
  Typography,
  Button,
  CardActions,
  CardContent,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 245,
    minWidth: 200,
    margin: 8, padding: 8
  },
  selectedCard: {
    maxWidth: 245,
    minWidth: 200,
    margin: 8, padding: 8,
    border: `2px solid green`,

  },
  media: {
    height: 100,
    width: 100,
    //borderRadius: 60,
  },
}));

const CourseItem = (props) => {
  const classes = useStyles();
  const { standardId, courseId } = props;

  return (
    <Card className={props.courseId == props.selectedCourseId ? classes.selectedCard : classes.card} key={props.standardId.toString()}
    // style={props.courseId == props.selectedCourseId ? { background: "lightgreen", } : {}}
        onClick={props.onClick}>
      <div style={{ justifyContent: "center", display: "flex", marginTop: 8 }}>
        <CardMedia
          className={classes.media}
          image={props.imageUrl ? props.imageUrl : scienceImage}
         
        />
      </div>
      <CardContent style={{ padding: 8, paddingBottom: 0 }}>
        <Typography
          style={{
            textAlign: "center",
          }}
          gutterBottom
          variant="subtitle1"
          component="h4"
        >
          {props.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CourseItem;
