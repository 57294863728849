import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./routing/PrivateRoute";
import Account from "./pages/Account";
import Institutes from "./pages/Institutes";
import InstituteContent from "./pages/InstituteContent";
import Groups from "./pages/Groups";
import NewGroup from "./pages/NewGroup";
import Teachers from "./pages/Teachers";
import Students from "./pages/Students";

import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import EditGroup from "./pages/EditGroup";
import CreateStudent from "./pages/CreateStudent";
import CreateTeacher from "./pages/CreateTeacher";
import CreateMultipleStudents from "./pages/CreateMultipleStudents";
import CreateMultipleTeachers from "./pages/CreateMultipleTeachers";

import AddTeacher from "./pages/AddTeacher";
import InstituteGroups from "./pages/InstituteGroups";
import GroupDetail from "./pages/GroupDetails";
import InstituteDetail from "./pages/InstituteDetail";
import VerifyAccount from "./pages/VerifyAccount";
import PageNotFound from "./pages/PageNotFound";
import { Provider } from "react-redux";
import store from "./redux-store/store";

const Routes = (props) => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={Institutes} />
          <PrivateRoute exact path="/Profile" component={Account} />
          <PrivateRoute
            exact
            path="/Institute-Content"
            component={InstituteContent}
          />
          <PrivateRoute
            exact
            path="/Institute-Details"
            component={InstituteDetail}
          />
          <PrivateRoute
            exact
            path="/Institute-Groups"
            component={InstituteGroups}
          />
          <PrivateRoute exact path="/Groups" component={Groups} />
          <PrivateRoute exact path="/Create Group" component={NewGroup} />
          <PrivateRoute exact path="/edit-group" component={EditGroup} />
          <PrivateRoute
            exact
            path="/Group Details"
            component={GroupDetail}
          />
          <PrivateRoute exact path="/add-teacher" component={AddTeacher} />
          <PrivateRoute exact path="/Teachers" component={Teachers} />
          <PrivateRoute
            exact
            path="/Create Teacher"
            component={CreateTeacher}
          />
          <PrivateRoute
            exact
            path="/Create Teachers"
            component={CreateMultipleTeachers}
          />
          <PrivateRoute exact path="/Students" component={Students} />
          <PrivateRoute
            exact
            path="/Create Student"
            component={CreateStudent}
          />
          <PrivateRoute
            exact
            path="/Create Students"
            component={CreateMultipleStudents}
          />
          <Route exact path="/sign-in" component={SignIn} />
          <Route exact path="/verify-account" component={VerifyAccount} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default Routes;
