import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import Typography from "@material-ui/core/Typography";

import {
  makeStyles,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import AccountItem from "../components/AccountItem";
import Breadcrumbs from "../components/Breadcrumbs";
import { getAccountDetail } from "../store/actions/Account";
import { useSelector, useDispatch } from "react-redux";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import AppBar from '../components/AppBar'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Account = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
   const user = useSelector((state) => state.auth.user);
  const account = useSelector((state) => state.account.account);
  console.log("account" + account);

  useEffect(() => {
    dispatch(getAccountDetail(user.id));
  }, [user.id,dispatch]);

  return (
    <div>
      <AppBar />

<div style={{padding:16}}>
        <Breadcrumbs />
        <div
          style={{
            //alignContent: "center",
            //alignItems: "center",
            justifyContent: "center",
            // alignSelf: "center",
           // display: "flex",
          }}
        >
          <div>
            <AccountItem account={account} />
            {/* <List>
              <ListItem>
                <ListItemText
                  primary="Name"
                  secondary={false ? "Secondary text" : null}
                />

                <ListItemText
                  primary="Name"
                  secondary={false ? "Secondary text" : null}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
