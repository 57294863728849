import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import Typography from "@material-ui/core/Typography";
import Table from "../components/TeacherTable";
import { makeStyles, Fab, Grid, Tooltip, Avatar, Modal, Button } from "@material-ui/core";
import MaterialTable from "material-table";
import Select from "../components/Select";
import Theme from "../Theme";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Breadcrumbs from "../components/Breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import { getGroupStudents, deleteStudent } from "../store/actions/Students";
import { getInstituteGroups } from "../store/actions/Groups";
import { getAllInstitutesByMainInstituteId } from "../store/actions/Institutes";
import moment from "moment";
import AppBar from '../components/AppBar'

const useStyles = makeStyles((theme) => ({
  table: {
    "& tbody>.MuiTableRow-root:hover": {
      background: "whitesmoke",
    },
  },
  paper: {
    position: "absolute",
    width: 600,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Students = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const insts = useSelector((state) => state.institutes.institutes);
  const user = useSelector((state) => state.auth.user);
  const grps = useSelector((state) => state.groups.groups);
  const stds = useSelector((state) => state.students.students);
  const [institute, setInstitute] = React.useState("");
  const [group, setGroup] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [studentId, setStudentId] = React.useState("");

  const handleInstituteChange = (e) => {
    e.preventDefault();
    setInstitute([e.target.value]);
    dispatch(getInstituteGroups(insts[e.target.value]._id));
  };
  const handleGroupChange = (e) => {
    e.preventDefault();
    setGroup([e.target.value]);
    dispatch(getGroupStudents(grps[e.target.value]._id));
  };
  useEffect(() => {
    dispatch(getAllInstitutesByMainInstituteId(user.id));
  }, [dispatch, user.id]);
  const onClickDeleteAlert = ({ studentId }) => {
    setStudentId(studentId)
    setOpen(true)
  }

  const onClickDeleteStudent = () => {
    dispatch(deleteStudent({ studentId }))
    setOpen(false)
  }
  const col = [
    {
      title: "Image",
      field: "imageUrl",
      render: (rowData) => <Avatar alt="image" src={rowData.imageUrl} />,
      export: false,
    },

    { field: "name", title: "Name", width: 150 },
    { field: "email", title: "Email", width: 150 },
    { field: "phoneNumber", title: "Phone #", width: 150 },

    // { field: "role", title: "Role", width: 100 },

    { field: "parentName", title: "Parent's Name", width: 150 },
    { field: "parentEmail", title: "Parent's Email", width: 150 },
    { field: "parentPhoneNumber", title: "Parent's Phone #", width: 150 },
    {
      field: "createdAt",
      title: "Created on",
      width: 130,

      render: (rowData) => <div>{moment(rowData.createdAt).format('DD/MM/YYYY')}</div>,
      export: false
    },
  ];

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Delete Student</h2>
      <p id="simple-modal-description">
        Are sure you want to delete the student?
      </p>
      <div>
        <Button
          variant="contained"
          style={{ margin: 8, minWidth: 100 }}
          color="primary"
          onClick={onClickDeleteStudent}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          style={{ margin: 8, minWidth: 100 }}
          color="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
  return (
    <div>
      <AppBar />

      <div style={{ padding: 16 }}>
        <Breadcrumbs />
        <div
          style={{
            //width: 800,
            alignItems: "center",
            justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            flex: 1,
            // display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ width: 800 }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={12} sm={6}>
                <Select
                  options={insts}
                  firstMenu="Select Institute"
                  label="Institute"
                  value={institute}
                  onChange={handleInstituteChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Select
                  options={grps}
                  firstMenu="Select Group"
                  label="Group"
                  value={group}
                  onChange={handleGroupChange}
                />
              </Grid>
            </Grid>
          </div>

          <div
            style={{
              width: 800,
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              //flex: 1,
              //display: "flex",
            }}
          >
            <div className={classes.table} style={{ maxWidth: "100%" }}>
              <MaterialTable
                theme={Theme}
                columns={col}
                data={stds}
                title="Students"
                options={{
                  exportButton: true,
                  actionsColumnIndex: -1,
                  // selection: true,
                }}
                // editable={{
                //   onRowDelete: (oldData) =>
                //     new Promise((resolve, reject) => {
                //       setTimeout(() => {
                //         onClickDeleteAlert({ studentId: oldData._id })
                //         resolve();
                //       }, 1000);
                //     }),
                // }}
                actions={[

                  {
                    icon: 'delete',
                    tooltip: 'Delete User',
                    onClick: (event, rowData) => { onClickDeleteAlert({ studentId: rowData._id }) }
                  }
                ]}
              />
              <Modal
                open={open}
                onClose={() => { setOpen(false) }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {body}
              </Modal>
            </div>

            {/* <Table rows={stds} /> */}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            bottom: 0,
            right: 0,
            marginBottom: 16,
            marginRight: 16,
            position: "fixed",
          }}
        >
          <Tooltip title="Create New Student" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/create-student");
              }}
            >
              <PersonAddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
          <Tooltip title="Create Multiple Student" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/create-multiple-students");
              }}
            >
              <GroupAddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Students;
