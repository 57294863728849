import React from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";

const Header = (props) => {
  
  return (
    <div
      style={{
        backgroundColor: "#1976d2",
        paddingTop: 16,
        paddingBottom: 16,
        // minHeight: 50,
        marginBottom: 8,
        paddingLeft: 30,
        flexDirection:'row',
        display:'flex',
        // maxWidth:'800px'
      }}
    >
    
        <div style={{display:'flex',flexGrow:1}}><Typography key={'name'} style={{ color: 'white' }}>{props.header} </Typography></div>
       
    </div>
  );
};

export default Header;
