import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import {setAlert} from '../actions/Alert'
export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const EDIT_GROUP_REQUEST = "EDIT_GROUP_REQUEST";
export const EDIT_GROUP_FAILURE = "EDIT_GROUP_FAILURE";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";

export const GET_INSTITUTE_GROUPS_REQUEST = "GET_INSTITUTE_GROUPS_REQUEST";
export const GET_INSTITUTE_GROUPS_FAILURE = "GET_INSTITUTE_GROUPS_FAILURE";
export const GET_INSTITUTE_GROUPS_SUCCESS = "GET_INSTITUTE_GROUPS_SUCCESS";
export const GET_GROUP_DETAIL_REQUEST = "GET_GROUP_DETAIL_REQUEST";
export const GET_GROUP_DETAIL_FAILURE = "GET_GROUP_DETAIL_FAILURE";
export const GET_GROUP_DETAIL_SUCCESS = "GET_GROUP_DETAIL_SUCCESS";

export const GET_GROUP_SUBJECTS_REQUEST = "GET_GROUP_SUBJECTS_REQUEST";
export const GET_GROUP_SUBJECTS_FAILURE = "GET_GROUP_SUBJECTS_FAILURE";
export const GET_GROUP_SUBJECTS_SUCCESS = "GET_GROUP_SUBJECTS_SUCCESS";
export const SET_GROUP = "SET_GROUP";
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";
const timeout = 8000;
export const createGroup = (userData) => async (dispatch) => {
  const {
    name,
    description,
    instituteId,
    boardId,
    standardId,
    courseId,
    subjectIds,
  } = userData;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: CREATE_GROUP_REQUEST,
  });

  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        name,
        description,
        instituteId,
        boardId,
        standardId,
        courseId,
        subjectIds,
      });

      const res = await axios.post(`${baseUrl}/api/group/create`, body, config);
      if (res.status === 200) {
        console.log(res);
        dispatch({
          type: CREATE_GROUP_SUCCESS,
          payload: res.data.message,
        });
        dispatch(setAlert({message:res.data.message,alertType:'success'}))
      } else {
        dispatch({
          type: CREATE_GROUP_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch(setAlert({message:err.response.data.messages[0].msg,alertType:'error'}))
      dispatch({
        type: CREATE_GROUP_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};
export const setGroup = (groupId) => async (dispatch) => {
  dispatch({
    type: SET_GROUP,
    payload: groupId,
  });
};
export const setSelectedGroup = ({group}) => async (dispatch) => {
  console.log(group);
  dispatch({
    type: SET_SELECTED_GROUP,
    payload: group,
  });
};
export const getGroupDetail = (groupId) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_GROUP_DETAIL_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/api/group/${groupId}`, config);
      console.log(res);
      if (res.status === 200) {
        dispatch({
          type: GET_GROUP_DETAIL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_GROUP_DETAIL_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log(err);
      clearTimeout(id);
      dispatch({
        type: GET_GROUP_DETAIL_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getGroupSubjects = (groupId) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_GROUP_SUBJECTS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/group/subjects/${groupId}`,
        config
      );
      console.log(res);

      if (res.status === 200) {
        dispatch({
          type: GET_GROUP_SUBJECTS_SUCCESS,
          payload: res.data.subjects,
        });
      } else {
        dispatch({
          type: GET_GROUP_SUBJECTS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: GET_GROUP_SUBJECTS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getInstituteGroups = (instituteId) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_INSTITUTE_GROUPS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/group/institute/${instituteId}`,
        config
      );
      console.log(res.data);
      if (res.status === 200) {
        dispatch({
          type: GET_INSTITUTE_GROUPS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_INSTITUTE_GROUPS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: GET_INSTITUTE_GROUPS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};
export const editGroup = (id, userData) => async (dispatch) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const { name, description } = userData;
  dispatch({
    type: EDIT_GROUP_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      const body = JSON.stringify({
        id,
        name,
        description,
      });

      const res = await axios.put(`${baseUrl}/group/`, body, config);
      if (res.status === 200) {
        dispatch({
          type: EDIT_GROUP_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: EDIT_GROUP_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      clearTimeout(id);
      dispatch({
        type: EDIT_GROUP_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};
