import React from "react";
import Drawer from "../components/Drawer";
//import Typography from "@material-ui/core/Typography";
import Select from "../components/Select";
import CustomTextField from "../components/CustomTextField";
import SubjectItem from "../components/SubjectItem";
import Typography from "@material-ui/core/Typography";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import BackupIcon from "@material-ui/icons/Backup";
import {
  makeStyles,
  Grid,
  Button,
  Divider,
  Paper,
  Avatar,
} from "@material-ui/core";
import Breadcrumbs from '../components/Breadcrumbs'
import AppBar from '../components/AppBar'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const EditGroup = () => {
  const classes = useStyles();
  const [institute, setInstitute] = React.useState("");
  const [groupName, setGroupName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [boardId, setBoardId] = React.useState("");
  const [standard, setStandard] = React.useState("");
  const [courseId, setCourseId] = React.useState("");
  //subject object
  const [subjects, setSubjects] = React.useState([]);
  const [imageUrl, setImageUrl] = React.useState("");
  
  //console.log("std" + JSON.stringify(standard));
  const institutes = [
    { name: "National Collge", id: "National Collge Id" },
    { name: "RV Collge", id: "RV Collge Id" },
  ];
  const boards = [
    { name: "CBSE", id: "cbseId" },
    { name: "ICSE", id: "icseId" },
  ];
  const standards = [
    { name: "Clss 11", id: "class12Id" },
    { name: "Class 12", id: "class11Id" },
  ];
  const courses = [
    { name: "Science", id: "scienceId" },
    { name: "commerce", id: "commerceId" },
  ];

  const subs = ["1", "2", "3", "4"];
  const handleInstituteChange = (e) => {
    e.preventDefault();
    //console.log("change");
    //console.log(e.target.value);
    setInstitute(e.target.value && institutes[e.target.value]);
  };

  const handleGroupNameChange = (e) => {
    // e.preventDefault();
    console.log("grp name");
    console.log(e.target.value);
    setGroupName(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    e.preventDefault();
    //console.log("change");
    //console.log(e.target.value);
    setDescription(e.target.value);
  };
  const handleBoardChange = (e) => {
    e.preventDefault();
    //console.log("change");
    //console.log(e.target.value);
    setBoardId(e.target.value && boards[[e.target.value]]);
  };
  const handleStandardChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setStandard(e.target.value && standards[e.target.value]);
  };
  const handleCourseChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setCourseId(e.target.value && courses[e.target.value]);
  };

  return (
    <div>
  <AppBar />

<div style={{padding:16}}>
      <Breadcrumbs/>
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            marginLeft: 40,
          }}
        > 
          <CustomTextField
            label="Group Name"
            value={groupName}
            onChange={handleGroupNameChange}
          />
          <CustomTextField
            label="Description"
            value={description}
            onChange={handleDescriptionChange}
          />

          <div>
            <Typography variant="p" component="h5">
              Group Image
            </Typography>
            <Typography
              color="textSecondary"
              variant="p"
              component="p"
              gutterBottom
            >
              Upload group profile image, it is visible to all group memebers
            </Typography>
            <Paper variant="outlined" style={{ height: 160, display: "grid" }}>
              <div
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  style={{ width: 80, height: 80, margin: 8 }}
                  variant="square"
                  className={classes.square}
                  src="../assets/images/science.png"
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Change Image
                </Button>
              </div>
            </Paper>
          </div>
          <div style={{ marginTop: 16 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.button}
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGroup;
