import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import {
  ListItem,
  CardMedia,
  Card,
  CardActionArea,
  Typography,
} from "@material-ui/core";
import subjectIamge from "../assets/images/book.png";

const useStyles = makeStyles((theme) => ({

  card: {
    maxWidth: 120,
    minWidth: 120,
    margin: 8, padding: 8,
    textAlign: "-webkit-center",
    display: "flex",
  },
  selectedCard: {
    maxWidth: 120,
    minWidth: 120,
    margin: 8, padding: 8,
    textAlign: "-webkit-center",
    display: "flex",
    border: `2px solid green`,

  },
}));

const CheckboxListSecondary = (props) => {
  const classes = useStyles();

  return (
    <Card
      key={props.subject._id.toString()}
      className={props.subject.isSelected ? classes.selectedCard : classes.card}
    >
      <CardActionArea onClick={props.onChange}>
        <CardMedia
          style={{ width: 80, height: 80 }}
          image={props.subject.imageUrl ? props.subject.imageUrl : subjectIamge}
        />
        <Typography>{props.subject.name}</Typography>
      </CardActionArea>
    </Card>
  );
};

export default CheckboxListSecondary;
