import {
    GET_INSTITUTE_SUBJECTS_BY_COURSE_FAILURE,
    GET_INSTITUTE_SUBJECTS_BY_COURSE_SUCCESS,
    GET_INSTITUTE_SUBJECTS_BY_COURSE_REQUEST,
    GET_INSTITUTE_COURSES_BY_STANDARD_SUCCESS,
    GET_INSTITUTE_COURSES_BY_STANDARD_REQUEST,
    GET_INSTITUTE_COURSES_BY_STANDARD_FAILURE,
    GET_INSTITUTE_STANDARDS_BY_BOARD_FAILURE,
    GET_INSTITUTE_STANDARDS_BY_BOARD_SUCCESS,
    GET_INSTITUTE_STANDARDS_BY_BOARD_REQUEST,
    GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_REQUEST,
    GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_SUCCESS,
    GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_FAILURE,
    GET_INSTITUTE_CONTENT_PROVIDER_REQUEST,
    GET_INSTITUTE_CONTENT_PROVIDER_FAILURE,
    GET_INSTITUTE_CONTENT_PROVIDER_SUCCESS,
  } from "../actions/InstituteContent";
  
  const initialState = {
    instituteContentProviders: [],
    instituteBoards: [],
    instituteStandards: [],
    instituteCourses: [],
    instituteSubjects: [],
    isLoading: false,
    message: null,
    error: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_INSTITUTE_CONTENT_PROVIDER_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case GET_INSTITUTE_CONTENT_PROVIDER_SUCCESS:
        return {
          ...state,
          instituteContentProviders: action.payload,
          isLoading: false,
        };
  
      case GET_INSTITUTE_CONTENT_PROVIDER_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload.message,
        };
      case GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_SUCCESS:
        return {
          ...state,
          instituteBoards: action.payload,
          isLoading: false,
        };
  
      case GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload.message,
        };
  
      case GET_INSTITUTE_STANDARDS_BY_BOARD_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case GET_INSTITUTE_STANDARDS_BY_BOARD_SUCCESS:
        return {
          ...state,
          instituteStandards: action.payload,
          isLoading: false,
        };
  
      case GET_INSTITUTE_STANDARDS_BY_BOARD_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload.message,
        };
      case GET_INSTITUTE_COURSES_BY_STANDARD_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case GET_INSTITUTE_COURSES_BY_STANDARD_SUCCESS:
        return {
          ...state,
          instituteCourses: action.payload,
          isLoading: false,
        };
  
      case GET_INSTITUTE_COURSES_BY_STANDARD_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload.message,
        };
  
      case GET_INSTITUTE_SUBJECTS_BY_COURSE_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case GET_INSTITUTE_SUBJECTS_BY_COURSE_SUCCESS:
        return {
          ...state,
          instituteSubjects: action.payload,
          isLoading: false,
        };
  
      case GET_INSTITUTE_SUBJECTS_BY_COURSE_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload.message,
        };
  
      default:
        return state;
    }
  }
  