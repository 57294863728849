import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid } from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import scienceImage from "../assets/images/teamwork.png";
const useStyles = makeStyles({
  root: {
    maxWidth: 200,
    minWidth: 260,
  },
  media: {
    height: 90,
    width: 90,
    //borderRadius: 60,

    marginTop: 8,
  },
});

const GroupItem = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {/* <CardActionArea> */}
      <div style={{ justifyContent: "center", display: "flex" }}>
        <div>
          <CardMedia
            className={classes.media}
            image={props.group.imageUrl ? props.group.imageUrl : scienceImage}
            title="Contemplative"
          />
        </div>
      </div>
      <CardContent style={{paddingBottom:0}}>
        <Typography
          style={{
            textAlign: "center",
            marginBottom:0
          }}
          gutterBottom
          variant="body1"
          color="textSecondary"
          component="p"
        >
        {props.group.name}
        </Typography>
        <Typography
            style={{
              textAlign: "center",
            }}
            variant="body2"
            color="textSecondary"
            component="p"
          >
             Class: {props.group.standard.name}
          </Typography>
      </CardContent>
      {/* </CardActionArea> */}
      <CardActions style={{ justifyContent: "center" }}>
        <Button size="small" color="primary" onClick={props.onClick}>
          Group Details...
        </Button>
      </CardActions>
    </Card>
  );
};
export default GroupItem;
