//import isEmpty from "../../validation/is-empty";
import { SET_ALERT, REMOVE_ALERT } from "../actions/Alert";

const initialState = {
  alert: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };

    case REMOVE_ALERT:
      return {
        ...state,
        alert: null,
      };

    default:
      return state;
  }
}