import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
export const GET_INSTITUTE_CONTENT_PROVIDER_REQUEST =
  "GET_INSTITUTE_CONTENT_PROVIDER_REQUEST";
export const GET_INSTITUTE_CONTENT_PROVIDER_FAILURE =
  "GET_INSTITUTE_CONTENT_PROVIDER_FAILURE";
export const GET_INSTITUTE_CONTENT_PROVIDER_SUCCESS =
  "GET_INSTITUTE_CONTENT_PROVIDER_SUCCESS";

export const GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_REQUEST =
  "GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_REQUEST";
export const GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_FAILURE =
  "GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_FAILURE";
export const GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_SUCCESS =
  "GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_SUCCESS";

export const GET_INSTITUTE_STANDARDS_BY_BOARD_REQUEST =
  "GET_INSTITUTE_STANDARDS_BY_BOARD_REQUEST";
export const GET_INSTITUTE_STANDARDS_BY_BOARD_FAILURE =
  "GET_INSTITUTE_STANDARDS_BY_BOARD_FAILURE";
export const GET_INSTITUTE_STANDARDS_BY_BOARD_SUCCESS =
  "GET_INSTITUTE_STANDARDS_BY_BOARD_SUCCESS";

export const GET_INSTITUTE_COURSES_BY_STANDARD_REQUEST =
  "GET_INSTITUTE_COURSES_BY_STANDARD_REQUEST";
export const GET_INSTITUTE_COURSES_BY_STANDARD_FAILURE =
  "GET_INSTITUTE_COURSES_BY_STANDARD_FAILURE";
export const GET_INSTITUTE_COURSES_BY_STANDARD_SUCCESS =
  "GET_INSTITUTE_COURSES_BY_STANDARD_SUCCESS";

export const GET_INSTITUTE_SUBJECTS_BY_COURSE_REQUEST =
  "GET_INSTITUTE_SUBJECTS_BY_COURSE_REQUEST";
export const GET_INSTITUTE_SUBJECTS_BY_COURSE_FAILURE =
  "GET_INSTITUTE_SUBJECTS_BY_COURSE_FAILURE";
export const GET_INSTITUTE_SUBJECTS_BY_COURSE_SUCCESS =
  "GET_INSTITUTE_SUBJECTS_BY_COURSE_SUCCESS";
const timeout = 8000;
export const getAllContentProvidersByInstituteId =
  ({ instituteId }) =>
  async (dispatch) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const token = localStorage.getItem("token");
    dispatch({
      type: GET_INSTITUTE_CONTENT_PROVIDER_REQUEST,
    });
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await axios.get(
          `${baseUrl}/api/institute-content/content-provider/${instituteId}`,
          config
        );
        console.log("conent providers" + JSON.stringify(res));
        if (res.status === 200) {
          dispatch({
            type: GET_INSTITUTE_CONTENT_PROVIDER_SUCCESS,
            payload: res.data.instituteContentProviders,
          });
        } else {
          dispatch({
            type: GET_INSTITUTE_CONTENT_PROVIDER_FAILURE,
            payload: res.data.message,
          });
        }
      } catch (err) {
        clearTimeout(id);
        dispatch({
          type: GET_INSTITUTE_CONTENT_PROVIDER_FAILURE,
          payload: err.response.data.message,
        });
      }
    }
  };

export const getBoardsByContentProviderId =
  ({ instituteId, contentProviderId }) =>
  async (dispatch) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const token = localStorage.getItem("token");
    dispatch({
      type: GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_REQUEST,
    });

    console.log(instituteId, contentProviderId);
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await axios.get(
          `${baseUrl}/api/institute-content/board/${instituteId}/${contentProviderId}`,
          config
        );
        console.log("inst board" + JSON.stringify(res.data));
        if (res.status === 200) {
          dispatch({
            type: GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_SUCCESS,
            payload: res.data.instituteBoards,
          });
        } else {
          dispatch({
            type: GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_FAILURE,
            payload: res.data.message,
          });
        }
      } catch (err) {
        clearTimeout(id);
        dispatch({
          type: GET_INSTITUTE_BOARDS_BY_CONTENT_PROVIDER_FAILURE,
          payload: err.data.message,
        });
      }
    }
  };

export const getStandardsByBoardId =
  ({ instituteId, boardId }) =>
  async (dispatch) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    // console.log("boardId" + boardId + "  institute Id" + instituteId);
    const token = localStorage.getItem("token");
    dispatch({
      type: GET_INSTITUTE_STANDARDS_BY_BOARD_REQUEST,
    });
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await axios.get(
          `${baseUrl}/api/institute-content/standard/${instituteId}/${boardId}`,

          config
        );
        console.log("standards res" + JSON.stringify(res.data));
        if (res.status == 200) {
          dispatch({
            type: GET_INSTITUTE_STANDARDS_BY_BOARD_SUCCESS,
            payload: res.data.instituteStandards,
          });
        } else {
          dispatch({
            type: GET_INSTITUTE_STANDARDS_BY_BOARD_FAILURE,
            payload: res.data.message,
          });
        }
      } catch (err) {
        clearTimeout(id);
        dispatch({
          type: GET_INSTITUTE_STANDARDS_BY_BOARD_FAILURE,
          payload: err.response.data.message,
        });
      }
    }
  };

export const getCoursesByStandardId =
  ({ instituteId, standardId }) =>
  async (dispatch) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const token = localStorage.getItem("token");
    dispatch({
      type: GET_INSTITUTE_COURSES_BY_STANDARD_REQUEST,
    });

    console.log("standardId " + standardId);
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await axios.get(
          `${baseUrl}/api/institute-content/course/${instituteId}/${standardId}`,

          config
        );
        console.log("courses res" + JSON.stringify(res));
        if (res.status === 200) {
          dispatch({
            type: GET_INSTITUTE_COURSES_BY_STANDARD_SUCCESS,
            payload: res.data.instituteCourses,
          });
        } else {
          dispatch({
            type: GET_INSTITUTE_COURSES_BY_STANDARD_SUCCESS,
            payload: res.data.message,
          });
        }
      } catch (err) {
        clearTimeout(id);
        dispatch({
          type: GET_INSTITUTE_COURSES_BY_STANDARD_SUCCESS,
          payload: err.response.data.message,
        });
      }
    }
  };

export const getSubjectsByCourseId =
  ({ instituteId, courseId }) =>
  async (dispatch) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const token = localStorage.getItem("token");
    dispatch({
      type: GET_INSTITUTE_SUBJECTS_BY_COURSE_REQUEST,
    });
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await axios.get(
          `${baseUrl}/api/institute-content/subject/${instituteId}/${courseId}`,

          config
        );
        console.log("inst subjects" + JSON.stringify(res.data));

        if (res.status === 200) {
          console.log(res.data.instituteSubjects);
          const sbs = res.data.instituteSubjects.map((s) => {
            let c = { ...s, isSelected: false };
            return c;
          });

          dispatch({
            type: GET_INSTITUTE_SUBJECTS_BY_COURSE_SUCCESS,
            payload: sbs,
          });
        } else {
          dispatch({
            type: GET_INSTITUTE_SUBJECTS_BY_COURSE_FAILURE,
            payload: res.data.message,
          });
        }
      } catch (err) {
        clearTimeout(id);
        dispatch({
          type: GET_INSTITUTE_SUBJECTS_BY_COURSE_FAILURE,
          payload: err.response.data.message,
        });
      }
    }
  };
