import { combineReducers } from "redux";
import authReducer from "../store/reducers/Auth";
import Account from "../store/reducers/Account";
import Groups from "../store/reducers/Groups";
import Institutes from "../store/reducers/Institutes";
import MainInstitute from "../store/reducers/MainInstitute";
import Students from "../store/reducers/Students";
import Teachers from "../store/reducers/Teachers";
import InstituteContent from "../store/reducers/InstituteContent";
import Alert from '../store/reducers/Alert'
export default combineReducers({
  auth: authReducer,
  account: Account,
  groups: Groups,
  institutes: Institutes,
  mainInstitute: MainInstitute,
  students: Students,
  teachers: Teachers,
  instituteContent: InstituteContent,
  alert:Alert
});
