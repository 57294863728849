import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Grid } from "@material-ui/core";
import GroupItem from "../components/GroupItem";
import Breadcrumbs from "../components/Breadcrumbs";
import { getGroupDetail, setGroup } from "../store/actions/Groups";
import AppBar from '../components/AppBar'

const InstituteGroups = (props) => {
  const dispatch = useDispatch();
  const grps = useSelector((state) => state.groups.groups);
  const isLoading = useSelector((state) => state.groups.isLoading);
  const selectedInstitute = useSelector((state) => state.institutes.selectedInstitute);
  useEffect(() => {
    if (grps && isLoading == false) {
      props.history.push("/Home");
    }
  }, []);
  // console.log("grps" + grps);
  const onClickGetGroupDetail = (groupId) => {
    dispatch(getGroupDetail(groupId));
    dispatch(setGroup(groupId));
    props.history.push("/institute-groups-detail");
  };

  return (
    <div>
      <AppBar />

<div style={{padding:16}}>
        <Breadcrumbs />
        <div
          style={{
            //width: 800,
            alignItems: "center",
            justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            //flexDirection: "column",
          }}
        >
          <div style={{ width: 800 }}>
            <Grid
              container
              direction="row"
              //justify="center"
              //alignItems="center"
              spacing={3}
            >
              {grps.map((g, i) => (
                <Grid item key={i.toString()}>
                  <GroupItem
                    onClick={() => {
                      onClickGetGroupDetail(g._id);
                    }}
                    group={g}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstituteGroups;
