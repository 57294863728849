import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authUser = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          localStorage.token &&
          authUser.isAuthenticated === true
          //&&
          // authUser.role === "INSTITUTE_USER"
        ) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/sign-in",
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
