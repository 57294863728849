import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import subjectIamge from "../assets/images/book.png";

const GroupSubjectListItem = (props) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          alt="Remy Sharp"
          src={props.subject.imageUrl ? props.subject.imageUrl : subjectIamge}
        />
      </ListItemAvatar>
      <ListItemText
        primary={props.subject.name}
        secondary={props.subject.description}
      />
    </ListItem>
  );
};

export default GroupSubjectListItem;
